export const mergeDeep = (...objects) => {
  return objects.reduce((prev, obj) => {
    Object.keys(obj).forEach(key => {
      const pVal = prev[key];
      const oVal = obj[key];

      if (Array.isArray(pVal) && Array.isArray(oVal)) {
        prev[key] = pVal.concat(...oVal);
      }
      else if (isObject(pVal) && isObject(oVal)) {
        prev[key] = mergeDeep(pVal, oVal);
      }
      else {
        prev[key] = oVal;
      }
    });

    return prev;
  }, {});
}

export const copyDeep = (object) => {
  return JSON.parse(JSON.stringify(object));
}

export const deepEqual= (object1, object2) => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      areObjects && !deepEqual(val1, val2) ||
      !areObjects && val1 !== val2
    ) {
      return false;
    }
  }
  return true;
}
export const isObject = (object) => {
  return object && typeof object === 'object';
}

export const isObjectEmpty = (obj) => {
  return obj &&
    Object.keys(obj).length === 0 &&
    Object.getPrototypeOf(obj) === Object.prototype;
}

export const isArrayEmpty = (arr) => {
  return arr &&
    Array.isArray(arr) &&
    arr.length === 0;
}

export const isValueNullOrEmpty = (obj) => {
  return !obj || isObjectEmpty(obj) || isArrayEmpty(obj);
}

export const setNestedProp = (obj, value, path) => {
  let currObj = obj;

  for(let i = 0; i < path.length; i++) {
    if(i === path.length - 1) {
      currObj[path[i]] = value
    }

    if(!currObj[path[i]]) {
      currObj[path[i]] = {}
    }
  }
}