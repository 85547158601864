import * as React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useContext, useMemo} from 'react';
import {ManagerContext} from '@components/manager/ManagerContainer';
import {Container, Nav} from 'react-bootstrap';
import {loginManager, logoutManager} from '@hooks/managerApi';
import {snackbarError, snackbarSuccess} from '@helper/snackbarHelper';

const ManagerFooter = ({managerToken, setManager, setManagerToken}) => {
  const {manager, loggedIn} = useContext(ManagerContext);

  const {callApi: callManagerLogout, states: getLogoutState} = logoutManager();
  const {responseLogout, isLoadingLogout} = getLogoutState;

  const {t} = useTranslation();

  const logout = () => {
    if(!managerToken) return;

    callManagerLogout(null, managerToken)
      .then(res => {
        if(!res.error) {
          setManagerToken(null);
          setManager(null);
          snackbarSuccess(t('manager.snackbar.logoutSuccess'));
          return;
        }
        snackbarError(t('manager.snackbar.logoutFailed'));
        if (res.error) console.error(res.error);
      });
  }

  return (
    <>
      {
        !loggedIn
        ? <></>
        : (
            <Container className="scm-footer">
              <Nav as="ul">
              </Nav>
              <div className="manager-info">
                {
                  manager
                  ? <Trans i18nKey='manager.footer.loggedInAs'  values={manager} components={[<b />]}/>
                  : <></>
                }
                <a onClick={logout}>{t('manager.footer.logout')}</a>
              </div>
            </Container>
          )
      }
    </>
  );
}

export default ManagerFooter;