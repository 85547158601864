import * as React from 'react';
import {ReactComponent as ReactLogo} from '@assets/CTW-BWT-Logo.svg';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useContext, useMemo} from 'react';
import {ManagerContext} from '@components/manager/ManagerContainer';
import {Container, Nav, Placeholder, Spinner} from 'react-bootstrap';
import classNames from 'classnames';
import {useLocation} from 'react-router-dom';

const ManagerNavigation = () => {
  const {manager, loggedIn} = useContext(ManagerContext);
  const routes = useSelector(state => state.config.manager.routes);

  const {t} = useTranslation();

  const location = useLocation();

  const navItems = useMemo(() => {
    return Object.keys(routes).map((key, index) => {
      const route = routes[key];

      let isActiveRoute = location.pathname === route.path;
      if (route.children) {
        route.children.map((childRoute) => {
          isActiveRoute |= location.pathname === route.path+childRoute.path;
        });
      }

      const classes = classNames({
        active: isActiveRoute,
      });

      if (!(route.requireAdmin ?? false)
        || (manager?.isAdmin ?? false)) {

        if (!manager) {
          return (
            <Placeholder as={Nav.Item} animation="glow" className={classes} key={index} as="li">
              <Placeholder as={Nav.Link}>&nbsp;</Placeholder>
            </Placeholder>
          );
        }

        return (
          <Nav.Item className={classes} key={index} as="li">
            <Nav.Link href={route.path}>{t(`manager.navigation.${key}`)}</Nav.Link>
          </Nav.Item>
        )
      }
    });
  }, [routes, manager, location]);

  return (
    <>
      {
        !loggedIn
          ? <></>
          : (
            <Container className="scm-navigation">
              <Nav defaultActiveKey="/manager" as="ul">
                {navItems}
              </Nav>
              <div className="logo right">
                <ReactLogo />
              </div>
            </Container>
          )
      }
    </>
  );
}

export default ManagerNavigation;