import * as React from 'react';
import {updateLanguageByParam} from '@helper/languageHelper';
import {useTranslation} from 'react-i18next';
import {Container, Row, Col} from 'react-bootstrap';
import QrCodeScanner from '@components/QrCodeScanner';
import {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {showBackground} from '@redux/slices/backgroundSlice';
import {resetHeader} from '@redux/slices/layoutSlice';

const CommissionHomePage = ({children}) => {
  const {i18n} = useTranslation();
  useEffect(() => {
    updateLanguageByParam({i18n});
  }, [i18n]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(showBackground());
    dispatch(resetHeader());
  }, []);


  const {t} = useTranslation();
  const langCode = i18n.language;

  let detailRoute = useSelector(state => state.config.urls.commissionDetailPage);
  const baseRoute = useMemo(() => {
    const route = detailRoute.replace(':langCode', langCode);
    return route;
  }, [langCode, detailRoute]);

  const modifyScanResult = ({decodedText}) => (decodedText.length <= 32 ? decodedText : decodedText.slice(-32))

  return (
    <Container>
      <Row>
        <Col>
          <h1>{t('homepage.title')}</h1>
          <p>{t('homepage.greetings')}</p>
          <p>{t('homepage.description')}</p>
        </Col>
      </Row>
      <QrCodeScanner btnText={t('homepage.btnText')} baseRoute={baseRoute} routeParam={"commissionId"} modifyScanResult={modifyScanResult}/>
    </Container>
  );
}

export default CommissionHomePage;