import * as React from 'react';
import {useSelector} from 'react-redux';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Accordion} from 'react-bootstrap';
import {Image} from 'react-bootstrap-icons';


const CommissionLineItems = ({children}) => {
  const {t} = useTranslation();
  const commission = useSelector(state => state.commissions.commission);

  const lineItems = useMemo(() => {
    return commission?.commissionDetail?.cart?.lineItems ?? [];
  }, [commission]);

  return (
    <>
      {
        commission
        ? (
            <Accordion className="commission-line-items">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <span>
                    <b>{t('commission.lineitems.title')}</b><br />
                    {lineItems.length}  {t('commission.lineitems.products')}
                  </span>
                </Accordion.Header>
                <Accordion.Body>
                  {
                    lineItems.map((lineItem, key) => {
                      return (
                        <div className="commission-line-item" key={key}>
                          <div className="commission-line-item-image">
                            {
                              lineItem.image
                              ? <img src={lineItem.image}  />
                              : <Image size={50} />
                            }
                          </div>
                          <div className="commission-line-item-label">
                            {lineItem.label}
                          </div>
                        </div>
                      )
                    })
                  }
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          )
        : <></>
      }
    </>
  );
}

export default CommissionLineItems;