import * as React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useContext} from 'react';
import {ManagerContext} from '@components/manager/ManagerContainer';
import ManagerCard from '@components/manager/ManagerCard';

const ManagerWelcome = () => {
  const {manager, loggedIn} = useContext(ManagerContext);

  const {t} = useTranslation();


  return (
    <ManagerCard title={t('manager.overview.title')} expandingHeight={true}>
      <Trans i18nKey="manager.overview.description" components={{p: <p />, br: <br />}}/>
    </ManagerCard>
  );
}

export default ManagerWelcome;