import * as React from 'react';
import {useSelector} from 'react-redux';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {
  isCancelled,
  isCompleted,
  isInProgress,
  isOpen,
  isReadyForPickup,
  parseDate
} from '@helper/commissionHelper';
import {isObjectEmpty} from '@helper/objectHelper';


const CommissionHeader = ({children}) => {
  const {t} = useTranslation();

  const showCommissionState = useSelector(state => state.layout.showCommissionState);
  const commission = useSelector(state => state.commissions.commission);

  const number = useMemo(() => {
    return commission?.number ? commission.number.toString().slice(-2) : '';
  }, [commission]);


  const {date, time} = useMemo(() => {
    if (commission?.createdAt) {
      return parseDate(commission.createdAt)
    }
    return {date: '', time: ''};
  }, [commission]);


  const state = useMemo(() => {
    if (commission?.commissionState){
      const states = commission.commissionState;
      if (isCancelled(states)) {
        return 'cancelled';
      }

      if (isCompleted(states)) {
        return 'completed';
      }

      if (isInProgress(states)) {
        return !isReadyForPickup(states) ? 'inProgress' : 'readyForPickup';
      }

      if (isOpen(states)) {
        return 'open';
      }
    }
    return 'unknown';
  }, [commission]);


  return (
    <>
      {
        showCommissionState && !isObjectEmpty(commission)
        ? (
          <div className="commission-state">
            <div className="commission-badge">
              <div className="commission-number">#{number}</div>
              <div className="commission-createdate">{date}</div>
              <div className="commission-createtime"> {
                time
                ? <>{time} {t('commission.oclock')}</>
                : <></>
              }</div>
            </div>
            <div className="commission-state-text">
              <span>{t('commission.state')}:</span>&nbsp;
              <span className={'state '+state}>{t('commission.states.'+state)}</span>
            </div>
          </div>
          )
        : <></>
      }
    </>
  );
}

export default CommissionHeader;