import {useCallback, useRef, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as React from 'react';
import {loadCommissionsByStoreId} from '@hooks/api';
import {deepEqual, isObjectEmpty} from '@helper/objectHelper';
import {setList} from '@redux/slices/commissionsSlice';
import {useTranslation} from 'react-i18next';

const CommissionListMinimal = ({storeId}) => {
  const config = useSelector(state => state.config.commissionList);

  const dispatch = useDispatch();
  const {callApi: callCommissionsByStoreId, states: getCommissionsState} = loadCommissionsByStoreId();
  const {response} = getCommissionsState;
  const commissionsList = useSelector(state => state.commissions.list);
  const timeoutRef = useRef(null);

  const handleLoadCommissions = useCallback((abortSignal) => {
    if (storeId) {
      const data = {
        storeId: storeId
      };

      callCommissionsByStoreId(data, {signal: abortSignal})
        .then(res => {
          if (!res.error) {
            timeoutRef.current = setTimeout(() => {
              handleLoadCommissions(abortSignal)
            }, config.api.pullInterval);
            return;
          }

          if (res.error.name !== 'AbortError') {
            console.error(res.error);
          }
        });
    }
  }, [callCommissionsByStoreId, storeId]);

  useEffect(() => {
    const abortController = new AbortController();
    handleLoadCommissions(abortController.signal);
    return () => {
      clearTimeout(timeoutRef.current);
      abortController.abort();
    }
  }, [handleLoadCommissions]);

  useEffect(() => {
    if (response && !response.error) {
      const newList = {};
      response.payload.forEach((commission) => {
        newList[commission.uniqueIdentifier] = commission;
      });
      if (!deepEqual(commissionsList, newList)) {
        dispatch(setList(newList));
      }
    }
  }, [response]);


  const sortedLists = useMemo(() => {
    const lists = {
        'readyForPickup': [],
        'inProgress': [],
      };

    for (let uuid in commissionsList) {
      const state = commissionsList[uuid].commissionState;
      if (state) {
        if (state.readyForPickup) {
          lists.readyForPickup.push(commissionsList[uuid]);
        } else if (state.inProgress) {
          lists.inProgress.push(commissionsList[uuid]);
        }
      }
    }

    // now sort by time
    Object.keys(lists).forEach((listKey) => {
      lists[listKey].sort((a,b) => {
        if (a.commissionState[listKey] < b.commissionState[listKey]) {
          return -1;
        }
        if (b.commissionState[listKey] < a.commissionState[listKey]) {
          return 1;
        }
        return 0;
      });
    })
    return lists;
  }, [commissionsList]);

  const {t} = useTranslation();

  return (
    <div className="commission-list-wrapper minimal">
      {
        !isObjectEmpty(commissionsList)
        ? (
            Object.keys(sortedLists).map((listKey, key) => {
                const classNames = 'commission-list show '+listKey;
                return (
                    <div key={key} className={classNames}>
                      <div className="commission-list-title">{t('commission.states.'+listKey)}</div>
                      <div className="commission-list-items">
                      {
                        sortedLists[listKey].map(({uniqueIdentifier, number, commissionState}, index) => (
                          <div key={number} className="commission-list-item">#{number}</div>
                        ))
                      }
                      </div>
                    </div>
                )
            })
          )
        : <>{/* DO SOMETHING IF NOTHING TODO */}</>
      }
    </div>
  );
}

export default CommissionListMinimal;