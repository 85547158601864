import { format, formatInTimeZone, utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";

export const isOpen = (state) => {
  return !isInProgress(state) && !isCancelled(state) && !isCompleted(state);
}

export const isInProgress = (state) => {
  return state.inProgress !== null;
}

export const isReadyForPickup = (state) => {
  return state.readyForPickup !== null;
}

export const isPaid = (state) => {
  return state.paid !== null
}

export const isPrePaid = (state) => {
  return state.prepaid ?? false;
}

export const isCompleted = (state) => {
  return state.completed !== null
}

export const isCancelled = (state) => {
  return state.cancelled !== null
}

export const parseDate = (dateTimeString) => {
  const result = {date: '', time: ''};

  if (dateTimeString) {
    const date = zonedTimeToUtc(dateTimeString, 'UTC')
    if (date instanceof Date && !isNaN(date)) {
      result.date = format(date, 'dd.MM.yyyy');
      result.time = format(date, 'H:mm');
    }
  }

  return result;
}

export const isOrderPending = (state) => {
  return state?.commissionDetail?.orderId === 'pending';
}

export const validateEmail = (email) => {
  return /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,63}$/.test(email);
}
