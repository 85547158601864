import * as React from 'react';
import {updateLanguageByParam} from '@helper/languageHelper';
import {Trans, useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {Container, Row, Col} from 'react-bootstrap';
import QrCodeScanner from '@components/QrCodeScanner';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {hideBackground, showBackground} from '@redux/slices/backgroundSlice';
import {resetHeader} from '@redux/slices/layoutSlice';

const CoffeeHomePage = ({children}) => {
  const {langCode} = useParams();
  const {i18n} = useTranslation();
  useEffect(() => {
    updateLanguageByParam({i18n, langCode});
  }, [langCode,i18n]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(showBackground());
    dispatch(resetHeader());
  }, []);

  const {t} = useTranslation();

  const baseRoute = useSelector(state => state.config.urls.coffeeRedeemPage);

  return (
    <Container>
      <Row>
        <Col>
          <p>
            <Trans i18nKey='coffee.homepage.description'  values={{
              btnText: t('coffee.homepage.btnText')
            }} />
          </p>
        </Col>
      </Row>
      <QrCodeScanner btnText={t('coffee.homepage.btnText')} baseRoute={baseRoute} routeParam={"commissionId"}/>
    </Container>
  );
}

export default CoffeeHomePage;