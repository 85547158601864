import * as React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import {useEffect, useMemo} from 'react';
import {useSelector} from 'react-redux';

const HomePage = ({children}) => {

  const route = useSelector(state => state.config.bwtHomePage.url);
  useEffect(() => {
    window.location = route;
  }, []);


  return (
    <Container>
    </Container>
  );
}

export default HomePage;