import * as React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useMemo, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {Button, Form, FormControl, FormGroup, Spinner} from 'react-bootstrap';
import {loadCommission, subscribeToNewsletter} from '@hooks/api';
import {validateEmail} from '@helper/commissionHelper';
import {isObjectEmpty} from '@helper/objectHelper';
import {setCommission} from '@redux/slices/commissionsSlice';
import Flashbag from '@components/Flashbag';


const CommissionNewsletter = ({children}) => {
  const dispatch = useDispatch();
  const commission = useSelector(state => state.commissions.commission);

  const [isSubscribed, setIsSubscribed] = useState(false);

  const [email, setEMail] = useState('');
  const updateEmail = (event) => {
    setEMail(event.target.value);
  }

  useEffect(() => {
    if (commission?.commissionDetail?.subscribedNewsletter) {
      setIsSubscribed(true);
    }
  }, [commission])

  const {t} = useTranslation();

  const {callApi: callSubscribe, states: getSubscribeState} = subscribeToNewsletter();
  const {response: responseSubscribe, isLoading: isLoadingSubscribe} = getSubscribeState;

  const {callApi: callLoadCommission, states: getCommissionState} = loadCommission();
  const {response: responseCommission} = getCommissionState;

  const subscribe = () => {
    if (commission && commission?.uniqueIdentifier && validateEmail(email)) {
      const data = {
        uuid: commission.uniqueIdentifier,
        email: email,
      };

      callSubscribe(data)
        .then(res => {
          if(!res.error) {
            return;
          }

          if (res.error.name !== 'AbortError') {
            console.error(res.error);
          }
        });
    }
  }

  useEffect(() => {
    if (responseSubscribe?.payload?.success) {
      setIsSubscribed(true);
      reloadCommission();
    }
  }, [responseSubscribe])

  const reloadCommission = () => {
    console.log(commission);
    if (commission && commission?.uniqueIdentifier) {
      const data = {
        uuid: commission && commission?.uniqueIdentifier
      };

      callLoadCommission(data)
        .then(res => {
          if(!res.error) {
            return;
          }

          if (res.error.name !== 'AbortError') {
            console.error(res.error);
          }
        });
    }
  }

  useEffect(() => {
    if (responseCommission && !isObjectEmpty(responseCommission.payload)) {
      dispatch(setCommission(responseCommission.payload))
    }
  }, [responseCommission])

  return (
    <div className="commission-newsletter">
      {
        isLoadingSubscribe
        ? <Spinner animation="border" role="status" size="xxl" />
        : (
          <>
            {
              isSubscribed
              ? (
                <Flashbag type={"success"}>
                  <p>
                    <Trans i18nKey="commission.newsletter.subscribed" components={[<b />]}/>
                  </p>
                </Flashbag>
              )
              : (
                <>
                  <p>
                    <Trans i18nKey="commission.newsletter.description" components={[<b />]}/>
                  </p>
                  <Form onSubmit={subscribe}>
                    <FormControl type="text" onChange={updateEmail} value={email} />
                    <Button className={"btn-primary"} onClick={subscribe}>{t('commission.newsletter.button')}</Button>
                  </Form>
                </>
              )
            }
          </>
          )
      }
    </div>
  );
}

export default CommissionNewsletter;