import * as React from 'react';
import {useEffect, useMemo, useState} from 'react';
import {Button, Modal, ModalHeader, Spinner, Table} from 'react-bootstrap';
import {PencilSquare, PlusCircle, X, XCircle, XSquare} from 'react-bootstrap-icons';
import {useLocation, useNavigate} from 'react-router-dom';
import {mergeDeep} from '@helper/objectHelper';
import Barcode from 'react-barcode';
import {useTranslation} from 'react-i18next';
import tab from 'bootstrap/js/src/tab';

const defaultHeaderActions = {
  create: {
    icon: <PlusCircle size="30" />,
    action: '/create',
    adminOnly: true,
  },
}

const defaultRowActions = {
  edit: {
      icon: <PencilSquare size="30" />,
      action: '/edit'
    },
  delete: {
      icon: <XCircle className="danger" size="30" />,
      action: '/delete',
      adminOnly: true,
    },
};

const ManagerTable = ({
                        columns,
                        fetchApi = () => null,
                        authToken = null,
                        apiResponseProperty='data',
                        data= null,
                        isAdmin = false,
                        apiOptions={},
                        rowActions={},
                        headerActions={},
                        loaded=false,
                        handleLoaded=null,
                        actionsInFront=false,
                        additionalApiParameters=null,
}) => {
  const [useApi, setUseApi] = useState(false);
  const [tableData, setTableData] = useState(data  ?? []);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);

  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({content: null, btnText: '', btnAction: () => {}});
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const {callApi: fetchData, states: getState} = fetchApi(apiOptions, isAdmin) ?? {};
  const {response, isLoading} = getState ?? {};

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
      if (typeof fetchData === 'function') {
        setUseApi(true);
      }
  }, [data, fetchApi]);

  useEffect(() => {
      if (useApi) {
        if (!loaded) {
          fetchData(additionalApiParameters, authToken)
            .then(res => {
              let loadTableData = [];
              if (!res.error) {
                loadTableData = res.payload[apiResponseProperty] ?? [];
              } else {
                console.error(res.error);
              }
              setTableData(loadTableData);
              if (handleLoaded) {
                handleLoaded(true);
              }
            })
        }
      } else {
        setTableData(data ?? []);
      }
    }, [useApi, page, pageSize, loaded, data, additionalApiParameters]);


  const memoHeaderActions = useMemo(() => {
    return mergeDeep(defaultHeaderActions, headerActions);
  })

  const generateTableHeaderActions = (memoHeaderActions, isAdmin) =>{
    return (
      <th className="manager-table-actions text-center">{
        Object.entries(memoHeaderActions).map(([actionName, action], actionIndex) => {
          if (!action) return;
          if ((action.adminOnly ?? false) && !isAdmin) return;

          let onClick = null;
          switch(typeof action.action) {
            case 'function':
              onClick = action.action;
              break;
            case 'string':
              onClick = () => {
                navigate(location.pathname+action.action);
              }
              break;
            default:
              break;
          }

          return (
            <a key={actionIndex} className="manager-table-action" onClick={onClick}>
              {action.icon}
            </a>
          );
        })
      }</th>
    );
  };

  const memoRowActions = useMemo(() => {
    return mergeDeep(defaultRowActions, rowActions);
  })

  const generateTableRowActions = (row, memoRowActions, isAdmin) =>{
    return (
      <td className="manager-table-actions text-center">
        {
          Object.entries(memoRowActions).map(([actionName, action], actionIndex) => {
            if (!action) return;
            if ((action.adminOnly ?? false) && !isAdmin) return;

            let onClick = null;
            switch(typeof action.action) {
              case 'function':
                if (action.confirmModal) {
                  onClick = () => {
                    setModalData({
                      ...action.confirmModal,
                      btnAction: () => {
                        action.action(row.id);
                        handleCloseModal();
                      }
                    });
                    handleShowModal();
                  }
                } else {
                  onClick = () => {
                    action.action(row.id);
                  }
                }
                break;
              case 'string':
                onClick = () => {
                  navigate(location.pathname+action.action, { state: { data: row} })
                }
                break;
              default:
                break;
            }

            return (
              <a key={actionIndex} className="manager-table-action" onClick={onClick}>
                {action.icon}
              </a>
            );
          })
        }
      </td>
    );
  };

  return (
    <div className="manager-table-container">
      {
        isLoading
        ? <Spinner animation="border" role="status" size="xxl" />
        : (
          <>
            <Table className="manager-table table-responsive" striped bordered hover>
              <thead>
                <tr>
                  {
                    actionsInFront ? generateTableHeaderActions(memoHeaderActions, isAdmin) : <></>
                  }
                  {

                    columns.map((col, colIndex) => {
                      if ((col.adminOnly ?? false) && !isAdmin) return;
                      return <th key={colIndex}>{col.label}</th>;
                    })
                  }
                  {
                    !actionsInFront ? generateTableHeaderActions(memoHeaderActions, isAdmin) : <></>
                  }
                </tr>
              </thead>
              <tbody>
                {
                  tableData.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      {
                        actionsInFront ? generateTableRowActions(row, memoRowActions, isAdmin) : <></>
                      }
                      {
                        columns.map((col, colIndex) => {
                          if ((col.adminOnly ?? false) && !isAdmin) return;
                          return (
                            <td key={colIndex}>
                              {
                                (typeof col.property === 'function')
                                ? col.property(row)
                                : row[col.property] ?? ''
                              }
                            </td>
                          );
                        })
                      }
                      {
                        !actionsInFront ? generateTableRowActions(row, memoRowActions, isAdmin) : <></>
                      }
                    </tr>
                  ))
                }
              </tbody>
            </Table>

            <Modal className="dark-font" show={showModal} onHide={handleCloseModal} centered>
              {
                modalData.title ? <ModalHeader closeButton><b>{ modalData.title }</b></ModalHeader> : <></>
              }
              <Modal.Body>
                { modalData.content }
              </Modal.Body>
              {
                modalData.btnAction
                ? (
                    <Modal.Footer>
                      <Button onClick={modalData.btnAction} variant={modalData.btnVariant ?? "primary"}>{modalData.btnText}</Button>
                    </Modal.Footer>
                ): <></>
              }
            </Modal>
          </>
          )
      }
    </div>
  );
}

export default ManagerTable;