import * as React from 'react';
import { useSelector } from "react-redux";

const Background = ({children}) => {
  const isBackgroundVisible = useSelector(state => state.background.isVisible);

  const backgroundImage = useSelector(state => state.background.image);
  const backgroundVideo = useSelector(state => state.background.video);

  if (isBackgroundVisible) {
    if(backgroundVideo) {
      return (
        <div className="background">
          <video autoPlay muted loop key="background">
            {
              Object.entries(backgroundVideo).map(([type, src], key) => {
                const mediaType = 'video/' + type;
                return (
                  <source src={String(src)} type={mediaType} key={key}/>
                )
              })
            }
          </video>
        </div>
      );
    } else if (backgroundImage) {
      const backgroundStyle = {
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }

      return (
        <div className="background" style={backgroundStyle}></div>
      );
    }
  }

  // return empty if no background visible or set
  return (<></>);
}

export default Background;