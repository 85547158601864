import { createSlice } from '@reduxjs/toolkit'
import appConfig from '@src/config/config';

const initialState = {
  isVisible: true,
  video: appConfig.defaultVideo,
  defaultVideo: appConfig.defaultVideo,
  image: null,
}

export const backgroundSlice = createSlice({
  name: 'backgroundSlice',
  initialState,
  reducers: {
    showBackground: (state) => {
      state.isVisible = true;
    },

    hideBackground: (state) => {
      state.isVisible = false;
    },

    setBackgroundVideo: (state, action) => {
      state.video = action.payload;
    },

    resetBackgroundVideo: (state, action) => {
      state.video = state.defaultVideo;
    },

    setBackgroundImage: (state, action) => {
      state.image = action.payload;
    },
  },
})

export const { showBackground, hideBackground, setBackgroundImage, setBackgroundVideo, resetBackgroundVideo } = backgroundSlice.actions

export default backgroundSlice.reducer