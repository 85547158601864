import * as React from 'react';
import {updateLanguageByParam} from '@helper/languageHelper';
import {useTranslation} from 'react-i18next';
import {useParams, useNavigate} from 'react-router-dom';
import {Col, Container, Row} from 'react-bootstrap';
import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {hideBackground, showBackground} from '@redux/slices/backgroundSlice';
import {resetHeader, setHeaderBackground, setHeaderType} from '@redux/slices/layoutSlice';

const NotFoundPage = ({children}) => {
  const {langCode} = useParams();
  const {i18n} = useTranslation();
  useEffect(() => {
    updateLanguageByParam({i18n, langCode});
  }, [langCode,i18n]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(showBackground());
    dispatch(resetHeader());
  }, []);

  const {t} = useTranslation();

  const navigate = useNavigate();
  const routeChangeRoot = () =>{
    navigate('/scm/');
  }

  return (
    <Container>
      <Row>
        <Col>
          <h1>{t('pagenotfound.title')}</h1>
          <p>
            <a className="bwt-btn" onClick={routeChangeRoot}>{t('pagenotfound.returnText')}</a>
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export default NotFoundPage;