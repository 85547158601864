import apiClient from '@hooks/apiClient';

/**
 * data = { uuid: UUID }
 */
export const loadCommission = (options) =>
  apiClient('api/CommissionApi/LoadCommission', {
    method: 'GET',
    ...options
  });

/**
 * data = { storeId: Integer }
 */
export const loadCommissionsByStoreId = (options) =>
  apiClient('api/CommissionApi/LoadCommissions', {
    method: 'GET',
    ...options
  });

/**
 * data = { uuid: UUID }
 */
export const redeemCoffee = (options) =>
  apiClient('api/CommissionApi/RedeemCoffee', {
    method: 'GET',
    ...options
  });

/**
 * data = { uuid: UUID, email: EMAIL }
 */
export const subscribeToNewsletter = (options) =>
  apiClient('api/CommissionApi/RegisterForNewsletter', {
    method: 'post',
    headers: {
      'content-type': 'application/json'
    },
    ...options
  });