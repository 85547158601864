import * as React from 'react';
import ManagerContainer from '@components/manager/ManagerContainer';
import ManagerManagerList from '@components/manager/ManagerManagerList';
const ManagerManagersPage = () => {
  return (
    <ManagerContainer>
      <ManagerManagerList />
    </ManagerContainer>
  );
}

export default ManagerManagersPage;