import * as React from 'react';
import {useSelector} from 'react-redux';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {
  isCancelled,
  isCompleted,
  isInProgress,
  isPaid,
  isPrePaid,
  isReadyForPickup,
  parseDate
} from '@helper/commissionHelper';
import {isObjectEmpty} from '@helper/objectHelper';
import {Container} from 'react-bootstrap';
import classNames from 'classnames';
import {ArrowRightCircleFill, CheckCircleFill, XCircleFill} from 'react-bootstrap-icons';


const CommissionStateList = ({children}) => {
  const config = useSelector(state => state.config.commissionStateList);

  const {t} = useTranslation();

  const commission = useSelector(state => state.commissions.commission);

  const isCommissionCancelled = useMemo(() => {
    return commission?.commissionState ? isCancelled(commission.commissionState) : false;
  }, [commission]);

  const isCommissionPrepaid = useMemo(() => {
    return commission?.commissionState ? isPrePaid(commission.commissionState) : false;
  }, [commission]);

  const containerClasses = classNames({
    'commission-state-list': true,
    'cancelled': isCommissionCancelled,
    'prepaid': isCommissionPrepaid,
  });

  const {date: createDate, time: createTime} = parseDate(commission?.createdAt);



  return (
    <div className={containerClasses}>
      {
        commission?.commissionState && !isObjectEmpty(commission?.commissionState)
        ? (
          <>
            <div className="commission-state-list-state open done">
              <div className="commission-state-list-state-badge">
                <CheckCircleFill size={config.badgeSize}/>
              </div>
              <div className="commission-state-list-state-label">{t('commission.states.open')}</div>
              <div className="commission-state-list-state-date">{createDate} {createTime}</div>
            </div>
            {
              Object.keys(commission?.commissionState).map((stateKey, key) => {
                if (config.visibleStates.includes(stateKey)) {
                  const {date, time} = parseDate(commission.commissionState[stateKey]);
                  const className = classNames({
                    'commission-state-list-state': true,
                    [stateKey]: true,
                    'done': date !== '',
                  })

                  if ((!isCommissionCancelled && stateKey !== 'cancelled') || (isCommissionCancelled && date !== '')) {
                    return (
                      <div key={key} className={className}>
                        <div className="commission-state-list-state-badge">
                          {
                            date !== ''
                            ? (
                              <>
                                {
                                  stateKey === 'cancelled'
                                  ? <XCircleFill size={config.badgeSize}/>
                                  : <CheckCircleFill size={config.badgeSize}/>
                                }
                              </>
                              )
                            : <ArrowRightCircleFill size={config.badgeSize} />
                          }
                        </div>
                        <div className="commission-state-list-state-label">{t('commission.states.'+stateKey)}</div>
                        <div className="commission-state-list-state-date">{date} {time}</div>
                      </div>
                    )
                  }
                }
              })
            }
          </>
          )
        : <></>
      }
    </div>
  );
}

export default CommissionStateList;