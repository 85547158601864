import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  headerType: 'none',
  headerBackground: 'none',
  showCommissionState: false,
}

export const layoutSlice = createSlice({
  name: 'layoutSlice',
  initialState,
  reducers: {
    setHeaderType: (state, action) => {
      state.headerType = action.payload;
    },

    setHeaderBackground: (state, action) => {
      state.headerBackground = action.payload;
    },

    showCommissionState: (state, action) => {
      state.showCommissionState = true;
    },

    hideCommissionState: (state, action) => {
      state.showCommissionState = false;
    },

    resetHeader: (state, action) => {
      state.headerBackground = initialState.headerBackground;
      state.headerType = 'default';
      state.showCommissionState = initialState.showCommissionState;
    },
  },
})

export const { setHeaderType, setHeaderBackground, showCommissionState, hideCommissionState, resetHeader } = layoutSlice.actions

export default layoutSlice.reducer