import {useCallback, useState} from 'react';

const useApiClient = (url, fetchOptionsParam = {}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState(null);
  
  const [baseUrl] = useState(url);
  const [baseFetchOptions] = useState(fetchOptionsParam);
  
  const states = {isLoading, response};
  
  const callApi = useCallback((data = null, authToken = null, extraFetchOptions = {}) => {
    if(authToken) {
      baseFetchOptions.headers = {
        ...baseFetchOptions.headers,
        Authorization: authToken
      }
    }

    let urlAddition = '';

    if(data) {
      switch (baseFetchOptions.method.toUpperCase()){
        case 'POST':
          baseFetchOptions.body = JSON.stringify(data);
          break;
        case 'GET':
          const params = Object.entries(data).map(([key, val]) => `${key}=${val}`);
          urlAddition = '?' + params.join('&');
          break;
        default:
          break;
      }
    }
    setIsLoading(true);

    const fetchOptions = {
      ...baseFetchOptions,
      ...extraFetchOptions
    }

    return fetch(baseUrl + urlAddition, fetchOptions)
      .then(res => checkResponse(res))
      .catch(err => ({payload: undefined, error: err, errorDetails: err.errorDetails}))
      .then(res => {
        setIsLoading(false);
        setResponse(res);
        return res;
      });
  }, [baseFetchOptions, baseUrl]);

  return {
    callApi,
    states
  };
}

const checkResponse = async (res) => {
  let obj = {
    statusCode: res.status,
    payload: undefined,
    error: undefined
  }

  const text = await res.text();
  const payload = text ? JSON.parse(text) : {};

  if(res.ok){
    obj.payload = payload;
  }
  else{
    obj.error = res.statusText || 'An error occurred';
    obj.errorDetails = payload.errors ?? {};
  }

  return obj;
}

export default useApiClient;
export {checkResponse};