import * as React from 'react';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import classNames from 'classnames';
import {useSelector} from 'react-redux';

const CommissionReview = ({isPrimary}) => {
  const config = useSelector(state => state.config.commissionReview);

  const {t} = useTranslation();

  const btnClasses = useMemo( () => {
    return classNames({
      'btn': true,
      'btn-outline-primary': !isPrimary,
      'btn-primary': isPrimary,
    });
  }, [isPrimary]);


  return (
    <div className="commission-review">
      <h6>{t('commission.review.title')}</h6>
      <p>{t('commission.review.description')}</p>
      <p><b>{t('commission.review.short')}</b></p>
      <a class={btnClasses} href={config.rateUrl} target="_blank" rel="noreferrer">{t('commission.review.button')}</a>
    </div>
  );
}

export default CommissionReview;