import * as React from 'react';
import {Trans} from 'react-i18next';
import classNames from 'classnames';


const ManagerCard = ({title, children, expandingHeight}) => {
  const classes = classNames({
    'manager-card': true,
    'expanding-height': expandingHeight
  })

  return (
    <div className={classes}>
      <div className="manager-card-title">{ title }</div>
      <div className="manager-card-content">
        { children }
      </div>
    </div>
  );
}

export default ManagerCard;