import * as React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import classNames from 'classnames';
import {createRef, useEffect, useState} from 'react';
import {Badge, Button, Modal, ModalHeader} from 'react-bootstrap';
import Barcode from 'react-barcode';
import {Upc} from 'react-bootstrap-icons';
import {generateEAN13Code} from '@helper/generalHelper';


const ManagerBarcodeModal = ({value, fileName, barcodeFormat, barcodeHeight, iconSize=20}) => {
  fileName = fileName || `${barcodeFormat}_${value}`;

  const [show, setShow] = useState(false);
  const {t} = useTranslation();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const barcodeRef = createRef();


  const downloadSvg = () => {
    if (!barcodeRef ||  !barcodeRef.current?.renderElementRef?.current) {
      return
    }

    const svg = barcodeRef.current?.renderElementRef?.current;
    const base64doc = btoa(decodeURIComponent(encodeURIComponent(svg.outerHTML)));
    const a = document.createElement('a');
    const e = new MouseEvent('click');
    a.download = `${fileName}.svg`;
    a.href = 'data:image/svg+xml;base64,' + base64doc;
    a.dispatchEvent(e);
  }

  const downloadPng = () => {
    if (!barcodeRef ||  !barcodeRef.current?.renderElementRef?.current) {
      return
    }

    const svg = barcodeRef.current?.renderElementRef?.current;

    const canvas = document.createElement("canvas");
    const base64doc = btoa(decodeURIComponent(encodeURIComponent(svg.outerHTML)));
    const w = parseInt(svg.getAttribute('width'));
    const h = parseInt(svg.getAttribute('height'));
    const img_to_download = document.createElement('img');
    img_to_download.src = 'data:image/svg+xml;base64,' + base64doc;
    img_to_download.onload = (e) => {
      canvas.setAttribute('width', w);
      canvas.setAttribute('height', h);
      const context = canvas.getContext("2d");
      context.drawImage(img_to_download, 0, 0, w, h);
      const dataURL = canvas.toDataURL('image/png');
      if (window.navigator.msSaveBlob) {
        window.navigator.msSaveBlob(canvas.msToBlob(), `${fileName}.png`);
        e.preventDefault();
      } else {
        const a = document.createElement('a');
        const my_evt = new MouseEvent('click');
        a.download = `${fileName}.png`;
        a.href = dataURL;
        a.dispatchEvent(my_evt);
      }
    }
  }

  return (
    <>
      <Badge title={t('manager.barcode.modal.showBtn')} className="clickable" onClick={handleShow}><Upc size={iconSize} className="barcode-icon" /></Badge> <span className="barcode-text">{value}</span>

      <Modal show={show} onHide={handleClose} centered>
        <ModalHeader closeButton />
        <Modal.Body className="text-center">
          <Barcode ref={barcodeRef} value={value} format={barcodeFormat} height={barcodeHeight} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={downloadPng}>{t('manager.barcode.modal.downloadPngBtn')}</Button>
          <Button onClick={downloadSvg}>{t('manager.barcode.modal.downloadSvgBtn')}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ManagerBarcodeModal;