import * as React from 'react';
import {useSelector} from 'react-redux';
import {Trans, useTranslation} from 'react-i18next';
import QRCode from 'react-qr-code';

const CommissionFreeCoffee = ({children}) => {
  const commission = useSelector(state => state.commissions.commission);

  const {t} = useTranslation();

  return (
    <div className="commission-coffee">
      {
        !commission?.commissionDetail?.coffeeRedeemed
          ? (
            <>
              <h6>{t('commission.newsletter.redeemCoffeeTitle')}</h6>
              <p>
                <Trans i18nKey="commission.newsletter.redeemCoffee" components={[<b />]}/>
              </p>
              <div className="commission-newsletter-qrcode">
                <QRCode value={commission.uniqueIdentifier} />
              </div>
            </>
          )
          : <></>
      }
    </div>
  );
}

export default CommissionFreeCoffee;