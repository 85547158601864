import * as React from 'react';
import classNames from 'classnames';

const Flashbag = ({type, bold, textAlign, children}) => {

  const classes = classNames({
    'flashbag': true,
    'notice': type === 'notice',
    'error': type === 'error',
    'success': type === 'success',
    'warning': type === 'warning',
    'bold': bold === true,
    'text-align-center': textAlign === 'center',
    'text-align-left': textAlign === 'left',
    'text-align-right': textAlign === 'right',
  })


  return (
    <div className={classes}>
      {children}
    </div>
  );
}

export default Flashbag;