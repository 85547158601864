
import appConfig from '@src/config/config';
import {enqueueSnackbar} from 'notistack';


export const snackbarInfo = (message, autoHideDuration) => {
  snackbarMessage(message, {variant: 'info'}, autoHideDuration);
}
export const snackbarSuccess = (message, autoHideDuration) => {
  snackbarMessage(message, {variant: 'success'}, autoHideDuration);
}
export const snackbarWarning = (message, autoHideDuration) => {
  snackbarMessage(message, {variant: 'warning'}, autoHideDuration);
}
export const snackbarError = (message, autoHideDuration) => {
  snackbarMessage(message, {variant: 'error'}, autoHideDuration);
}


export const snackbarMessage = (message, options, autoHideDuration = appConfig.snackbar.defaultAutoHideDuration) => {
  if (autoHideDuration > 0 && !options.hasOwnProperty('autoHideDuration')) {
    options.autoHideDuration = autoHideDuration;
  }
  enqueueSnackbar(message, options);
}