import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {useContext, useEffect, useMemo, useState} from 'react';
import {ManagerContext} from '@components/manager/ManagerContainer';
import {useLocation, useNavigate} from 'react-router-dom';
import {Button, Col, Form, InputGroup, Row, Spinner} from 'react-bootstrap';
import ManagerCard from '@components/manager/ManagerCard';
import {basicOnChange, dirname, generateEAN13Code, validateEAN13} from '@helper/generalHelper';
import {createSalesman, editSalesman, fetchStoresList, getAssignedStores} from '@hooks/managerApi';
import {snackbarError, snackbarSuccess} from '@helper/snackbarHelper';
import ManagerDynamicList from '@components/manager/ManagerDynamicList';
import ManagerBarcodeModal from '@components/manager/ManagerBarcodeModal';
import {ArrowRepeat} from 'react-bootstrap-icons';
import {z} from 'zod';
import {useValidator} from '@hooks/useValidator';


const updateSalesmanSchema = z.object({
  firstname: z.string({required_error: 'manager.salesmen.edit.error.firstnameRequired'})
    .min(3, 'manager.salesmen.edit.error.firstnameToShort')
    .max(255, 'manager.salesmen.edit.error.firstnameToLong')
    .nonempty('manager.salesmen.edit.error.firstnameRequired'),
  lastname: z.string({required_error: 'manager.salesmen.edit.error.lastnameRequired'})
    .min(3, 'manager.salesmen.edit.error.lastnameToShort')
    .max(255, 'manager.salesmen.edit.error.lastnameToLong')
    .nonempty('manager.salesmen.edit.error.lastnameRequired'),
  ean: z.string({required_error: 'manager.salesmen.edit.error.eanRequired'})
    .nonempty('manager.salesmen.edit.error.eanRequired')
    .refine((value) => validateEAN13(value), 'manager.salesmen.edit.error.eanInvalid'),
  email: z.string({required_error: 'manager.salesmen.edit.error.emailRequired'})
    .email('manager.salesmen.edit.error.emailInvalid')
    .nonempty('manager.salesmen.edit.error.emailRequired'),
  address: z.string({required_error: 'manager.salesmen.edit.error.addressRequired'})
    .nonempty('manager.salesmen.edit.error.addressRequired'),
  zip: z.string({required_error: 'manager.salesmen.edit.error.zipRequired'})
    .nonempty('manager.salesmen.edit.error.zipRequired'),
  city: z.string({required_error: 'manager.salesmen.edit.error.cityRequired'})
    .nonempty('manager.salesmen.edit.error.cityRequired'),
  country: z.string({required_error: 'manager.salesmen.edit.error.countryRequired'})
    .nonempty('manager.salesmen.edit.error.countryRequired'),
  user: z.string({required_error: 'manager.salesmen.edit.error.createdByRequired'}),
});

const createSalesmanSchema = updateSalesmanSchema.extend({
  stores: z.array(z.any())
    .min(1, 'manager.salesmen.edit.error.noStoresSelected'),
});

const ManagerSalesmanEdit = () => {
  const {manager, managerToken} = useContext(ManagerContext);
  const {t} = useTranslation();

  const {state} = useLocation();
  const { data } = state ?? {};

  const [id, setId] = useState(null);
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [ean, setEan] = useState(generateEAN13Code());
  const [address, setAddress] = useState('');
  const [address2, setAddress2] = useState('');
  const [zip, setZip] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEMail] = useState('');
  const [stores, setStores] = useState([]);

  const isEanGenerationAllowed = !id || (manager?.isAdmin ?? false);

  // set values to the fields
  useEffect(() => {
    if (data) {
      setId(data.id ?? null);
      setFirstname(data.firstname ?? '');
      setLastname(data.lastname ?? '');
      setEan(data.ean ?? generateEAN13Code());
      setAddress(data.address ?? '');
      setAddress2(data.address2 ?? '');
      setZip(data.zip ?? '');
      setCity(data.city ?? '');
      setCountry(data.country ?? '');
      setPhone(data.phone ?? '');
      setEMail(data.email ?? '');
      setStores(data.stores ?? []);
    }
  }, [data]);

  const [formData, setFormData] = useState(null);
  useEffect(() => {
    const tmpFormData = {
      id: id,
      firstname: firstname,
      lastname: lastname,
      ean: ean,
      address: address,
      address2: address2 !== '' ? address2 : null,
      zip: zip,
      city: city,
      country: country,
      phone: phone !== '' ? phone : null,
      email: email,
      stores: stores.map((store) => store.id),
      user: manager?.username ?? '',
    };

    if (id) {
      tmpFormData.id = id;
    }
    setFormData(tmpFormData);
  }, [manager, id, firstname, lastname, ean, address, address2, zip, city, country, phone, email, stores]);

  const formSchema = useMemo(() => {
    return id ? updateSalesmanSchema : createSalesmanSchema;
  }, [id])

  const {validateForm, clearFormErrors, formErrors} = useValidator(formData, formSchema);
  useEffect(() => {
    const formErrorsValues = Object.values(formErrors);
    formErrorsValues.forEach((error) => {
      snackbarError(t(error));
    })
  },[formErrors]);

  const {callApi: callGetAllStores, states: getStateStores} = fetchStoresList(null, manager?.isAdmin ?? false);
  const {response: responseGetAllStores, isLoading: isLoadingAllStores} = getStateStores;
  const [availableStores, setAvailableStores] = useState([]);
  useEffect(() => {
    if (managerToken) {
      callGetAllStores(null, managerToken)
        .then(res => {
          if(res.error) {
            console.error(res.error);
            return;
          }
          setAvailableStores(res.payload.stores ?? []);
        })
    }
  }, [manager]);

  const location = useLocation();
  const navigate = useNavigate();
  const stepBack = () => {
    navigate(dirname(location.pathname));
  }

  const {callApi: callCreate, states: getStateCreate} = createSalesman();
  const {response: responseCreate, isLoading: isLoadingCreate} = getStateCreate;

  const {callApi: callEdit, states: getStateEdit} = editSalesman();
  const {response: responseEdit, isLoading: isLoadingEdit} = getStateEdit;

  const save = () => {
    clearFormErrors();
    if(!validateForm()) {
      return;
    }

    const update = {
      firstname: formData.firstname,
      lastname: formData.lastname,
      ean: formData.ean,
      address: formData.address,
      address2: formData.address2,
      zip: formData.zip,
      city: formData.city,
      country: formData.country,
      phone: formData.phone,
      email: formData.email,
      user: formData.user,
      stores: formData.stores,
    };

    if (id) {
      update.id = formData.id;
    }

    if (update.id ?? false) {
      callEdit(update, managerToken)
        .then(res => {
          if(res.error) {
            snackbarError(t('manager.snackbar.salesmanUpdateFailed'));
            console.error(res.error);
            Object.entries(res.errorDetails).forEach(([key, errors]) => {
              errors.forEach((error) => {
                snackbarError(error);
              })
            });
            return;
          }
          snackbarSuccess(t('manager.snackbar.salesmanUpdateSuccess'));
        });
    } else {
      callCreate(update, managerToken)
        .then(res => {
          if(res.error) {
            snackbarError(t('manager.snackbar.salesmanUpdateFailed'));;
            console.error(res.error);
            Object.entries(res.errorDetails).forEach(([key, errors]) => {
              errors.forEach((error) => {
                snackbarError(error);
              })
            });
            return;
          }
          if (res.payload?.id) setId(res.payload.id);
          snackbarSuccess(t('manager.snackbar.salesmanCreateSuccess'));
        });
    }
  }

  const updateEan = () => {
    if (!isEanGenerationAllowed) {
      snackbarError(t('manager.snackbar.generateEanNotAllowed'));
    }
    setEan(generateEAN13Code());
  }

  const columns = [
    {
      label: t('manager.stores.columns.name'),
      property: 'name',
    },
  ]

  return (
    <Form>
      {
        isLoadingAllStores
          ? <Spinner animation="border" role="status" size="xxl" />
          : (
            <>
              <ManagerCard title={t('manager.salesmen.edit.titleGeneral')}>
                {
                  isLoadingCreate || isLoadingEdit
                    ? <Spinner animation="border" role="status" size="xxl" />
                    : (
                      <>
                        <Row>
                          <Col xs={12} sm={6}>
                            <Form.Group className="mb-3">
                              <Form.Label>{t('manager.salesmen.edit.fields.name')}</Form.Label>
                              <Row>
                                <Col xs={12} lg={6}>
                                  <Form.Control
                                    className={formErrors?.firstname ? 'is-invalid' : ''}
                                    type="text"
                                    placeholder={t('manager.salesmen.edit.fields.firstname')}
                                    value={firstname}
                                    onChange={basicOnChange(setFirstname)}
                                  />
                                  {formErrors?.firstname ? <Form.Control.Feedback type="invalid">{t(formErrors?.firstname)}</Form.Control.Feedback> : <></>}
                                </Col>
                                <Col className="mt-1 mt-lg-0" xs={12} lg={6}>
                                  <Form.Control
                                    className={formErrors?.lastname ? 'is-invalid' : ''}
                                    type="text"
                                    placeholder={t('manager.salesmen.edit.fields.lastname')}
                                    value={lastname}
                                    onChange={basicOnChange(setLastname)}
                                  />
                                  {formErrors?.lastname ? <Form.Control.Feedback type="invalid">{t(formErrors?.lastname)}</Form.Control.Feedback> : <></>}
                                </Col>
                              </Row>
                            </Form.Group>
                            <Form.Group className="mb-3">
                              <Form.Label>{t('manager.salesmen.edit.fields.ean')}</Form.Label>
                              <InputGroup>
                                <ManagerBarcodeModal value={ean} barcodeFormat="EAN13" barcodeHeight={40} fileName={`${firstname}_${lastname}_${ean}`} iconSize={30}/>
                                {
                                  isEanGenerationAllowed
                                  ? (
                                      <InputGroup.Text onClick={updateEan} className="cursor-pointer">
                                        <ArrowRepeat size={30} />
                                      </InputGroup.Text>
                                    )
                                  : <InputGroup.Text >
                                      <ArrowRepeat size={30} color="var(--bs-gray-400)"/>
                                    </InputGroup.Text>
                                }
                              </InputGroup>
                              {formErrors?.ean ? <Form.Control.Feedback type="invalid" style={{display:'block'}}>{t(formErrors?.ean)}</Form.Control.Feedback> : <></>}
                            </Form.Group>
                            <Form.Group className="mb-3">
                              <Form.Label>{t('manager.salesmen.edit.fields.phone')}</Form.Label>
                              <Form.Control type="text" placeholder={t('manager.salesmen.edit.fields.phone')} value={phone} onChange={basicOnChange(setPhone)} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                              <Form.Label>{t('manager.salesmen.edit.fields.email')}</Form.Label>
                              <Form.Control
                                className={formErrors?.email ? 'is-invalid' : ''}
                                type="text"
                                placeholder={t('manager.salesmen.edit.fields.email')}
                                value={email}
                                onChange={basicOnChange(setEMail)}
                              />
                              {formErrors?.email ? <Form.Control.Feedback type="invalid">{t(formErrors?.email)}</Form.Control.Feedback> : <></>}
                            </Form.Group>
                          </Col>
                          <Col xs={12} sm={6}>
                            <Form.Group className="mb-3">
                              <Form.Label>{t('manager.salesmen.edit.fields.address')}</Form.Label>
                              <Form.Control
                                className={formErrors?.address ? 'is-invalid' : ''}
                                type="text"
                                placeholder={t('manager.salesmen.edit.fields.address')}
                                value={address}
                                onChange={basicOnChange(setAddress)}
                              />
                              {formErrors?.address ? <Form.Control.Feedback type="invalid">{t(formErrors?.address)}</Form.Control.Feedback> : <></>}
                            </Form.Group>
                            <Form.Group className="mb-3">
                              <Form.Label>{t('manager.salesmen.edit.fields.address2')}</Form.Label>
                              <Form.Control type="text" placeholder={t('manager.salesmen.edit.fields.address2')} value={address2} onChange={basicOnChange(setAddress2)} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                              <Form.Label>{t('manager.salesmen.edit.fields.countryZipCity')}</Form.Label>
                              <Row>
                                <Col xs={4} lg={2}>
                                  <Form.Control
                                    className={formErrors?.country ? 'is-invalid' : ''}
                                    type="text"
                                    placeholder={t('manager.salesmen.edit.fields.country')}
                                    value={country}
                                    onChange={basicOnChange(setCountry)}
                                  />
                                </Col>
                                <Col xs={8} lg={4}>
                                  <Form.Control
                                    className={formErrors?.zip ? 'is-invalid' : ''}
                                    type="text"
                                    placeholder={t('manager.salesmen.edit.fields.zip')}
                                    value={zip}
                                    onChange={basicOnChange(setZip)}
                                  />
                                </Col>
                                <Col className="mt-1 mt-lg-0" xs={12} lg={6}>
                                  <Form.Control
                                    className={formErrors?.city ? 'is-invalid' : ''}
                                    type="text"
                                    placeholder={t('manager.salesmen.edit.fields.city')}
                                    value={city}
                                    onChange={basicOnChange(setCity)}
                                  />
                                </Col>
                              </Row>
                              {formErrors?.country ? <Form.Control.Feedback type="invalid" style={{display:'block'}}>{t(formErrors?.country)}</Form.Control.Feedback> : <></>}
                              {formErrors?.zip ? <Form.Control.Feedback type="invalid" style={{display:'block'}}>{t(formErrors?.zip)}</Form.Control.Feedback> : <></>}
                              {formErrors?.city ? <Form.Control.Feedback type="invalid" style={{display:'block'}}>{t(formErrors?.city)}</Form.Control.Feedback> : <></>}
                            </Form.Group>
                          </Col>
                        </Row>
                      </>
                    )
                }
              </ManagerCard>

              <ManagerCard title={t('manager.managers.edit.titleStores')}>
                {
                  isLoadingCreate || isLoadingEdit
                    ? <Spinner animation="border" role="status" size="xxl" />
                    : (
                      <>
                        {formErrors?.stores ? <Form.Control.Feedback type="invalid" style={{display:'block'}}>{t(formErrors?.stores)}</Form.Control.Feedback> : <></>}
                        <ManagerDynamicList placeholder={t('manager.managers.edit.fields.stores')} setData={setStores} selection={stores ?? []} options={availableStores ?? []}  columns={columns} isAdmin={(manager?.isAdmin ?? false) || id === null} actionsInFront={true}/>
                      </>
                      )
                }
              </ManagerCard>

              {
                isLoadingCreate || isLoadingEdit
                  ? <></>
                  : (
                    <div className="controls">
                      <Button variant="light" onClick={stepBack}>{t('cancel')}</Button>
                      <Button variant="primary" onClick={save} className="ms-auto">{t('save')}</Button>
                    </div>
                  )
              }
            </>
          )
      }
    </Form>
  );
}

export default ManagerSalesmanEdit;