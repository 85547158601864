import * as React from 'react';
import ManagerContainer from '@components/manager/ManagerContainer';
import ManagerWelcome from '@components/manager/ManagerWelcome';
const ManagerWelcomePage = () => {
  return (
    <ManagerContainer>
      <ManagerWelcome />
    </ManagerContainer>
  );
}

export default ManagerWelcomePage;