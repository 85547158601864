import * as React from 'react';
import ManagerContainer from '@components/manager/ManagerContainer';
import ManagerStoreEdit from '@components/manager/ManagerStoreEdit';
const ManagerStoreEditPage = () => {
  return (
    <ManagerContainer>
      <ManagerStoreEdit />
    </ManagerContainer>
  );
}

export default ManagerStoreEditPage;