import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {useContext, useEffect, useMemo, useState} from 'react';
import {ManagerContext} from '@components/manager/ManagerContainer';
import ManagerCard from '@components/manager/ManagerCard';
import {useLocation, useNavigate} from 'react-router-dom';
import {Button, Col, Container, Form, Row, Spinner} from 'react-bootstrap';
import {basicOnChange, dirname} from '@helper/generalHelper';
import {createManager, editManager, fetchStoresList, resetManagerPassword} from '@hooks/managerApi';
import {snackbarError, snackbarSuccess} from '@helper/snackbarHelper';
import ManagerDynamicList from '@components/manager/ManagerDynamicList';
import {z} from 'zod';
import {useValidator} from '@hooks/useValidator';
import {mergeDeep} from '@helper/objectHelper';

const updateUserSchema = z.object({
    username: z.string({required_error: 'manager.managers.edit.error.usernameRequired'})
      .min(3, 'manager.managers.edit.error.usernameToShort')
      .max(255, 'manager.managers.edit.error.usernameToLong')
      .nonempty('manager.managers.edit.error.usernameRequired'),
  });

const createUserSchema = updateUserSchema.extend({
    stores: z.array(z.any())
      .min(1, 'manager.managers.edit.error.noStoresSelected'),
    password: z.string({required_error: 'manager.managers.edit.error.passwordRequired'})
      .min(8, 'manager.managers.edit.error.passwordToShort')
      .max(255, 'manager.managers.edit.error.passwordToLong'),
    confirmPassword: z.string({required_error: 'manager.managers.edit.error.passwordRequired'})
      .min(8, 'manager.managers.edit.error.passwordToShort')
      .max(255, 'manager.managers.edit.error.passwordToLong'),
    createBy: z.string({required_error: 'manager.managers.edit.error.createdByRequired'}),
  }).refine((data) => data.password === data.confirmPassword, {
    message: 'manager.managers.edit.error.passwordMismatch',
    path: ['confirmPassword'],
  });

const resetUserPasswordSchema = z.object({
  password: z.string({required_error: 'manager.managers.edit.error.passwordRequired'})
    .min(8, 'manager.managers.edit.error.passwordToShort')
    .max(255, 'manager.managers.edit.error.passwordToLong'),
  confirmPassword: z.string({required_error: 'manager.managers.edit.error.passwordRequired'})
    .min(8, 'manager.managers.edit.error.passwordToShort')
    .max(255, 'manager.managers.edit.error.passwordToLong'),
}).refine((data) => data.password === data.confirmPassword, {
  message: 'manager.managers.edit.error.passwordMismatch',
  path: ['confirmPassword'],
});

const ManagerManagerEdit = () => {
  const {manager, managerToken} = useContext(ManagerContext);
  const {t} = useTranslation();

  const {state} = useLocation();
  const { data } = state ?? {};

  const [id, setId] = useState(null);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [stores, setStores] = useState([]);
  // set values to the fields
  useEffect(() => {
    if (data) {
      setId(data.id ?? null);
      setUsername(data.username ?? '');
      setIsAdmin(data.isAdmin ?? false);
      setStores(data.stores ?? []);
    }
  }, [data]);


  const [formData, setFormData] = useState(null);
  useEffect(() => {
    const tmpFormData = {
      username: username,
      isAdmin: isAdmin,
      stores: stores.map((store) => store.id),
    };

    if (id) {
      tmpFormData.id = id;
    } else {
      tmpFormData.password = password;
      tmpFormData.confirmPassword = confirmPassword;
      tmpFormData.createBy = manager?.username ?? '';
    }
    setFormData(tmpFormData);
  }, [manager, id, username, password, confirmPassword, isAdmin, stores]);

  const formSchema = useMemo(() => {
    return id ? updateUserSchema : createUserSchema;
  }, [id])

  const {validateForm, clearFormErrors, formErrors} = useValidator(formData, formSchema);
  useEffect(() => {
    const formErrorsValues = Object.values(formErrors);
    formErrorsValues.forEach((error) => {
      snackbarError(t(error));
    })
  },[formErrors]);


  const {callApi: callGetAllStores, states: getStateStores} = fetchStoresList(null, manager?.isAdmin ?? false);
  const {response: responseGetAllStores, isLoading: isLoadingAllStores} = getStateStores;
  const [availableStores, setAvailableStores] = useState([]);
  useEffect(() => {
    if (managerToken) {
      callGetAllStores(null, managerToken)
        .then(res => {
          if(res.error) {
            console.error(res.error);
            return;
          }
          setAvailableStores(res.payload.stores ?? []);
        })
    }
  }, [manager]);


  const location = useLocation();
  const navigate = useNavigate();
  const stepBack = () => {
    navigate(dirname(location.pathname));
  }

  const {callApi: callCreate, states: getStateCreate} = createManager();
  const {response: responseCreate, isLoading: isLoadingCreate} = getStateCreate;

  const {callApi: callEdit, states: getStateEdit} = editManager();
  const {response: responseEdit, isLoading: isLoadingEdit} = getStateEdit;

  const {callApi: callResetPassword, states: getStateResetPassword} = resetManagerPassword();
  const {response: responseResetPassword, isLoading: isLoadingReset} = getStateResetPassword;

  const save = () => {
    clearFormErrors();
    clearResetFormError();
    if(!validateForm()) {
      return;
    }

    const update = {
      username: formData.username,
      isAdmin: formData.isAdmin,
      stores: formData.stores,
    };

    if (formData.id) {
      update.id = formData.id;
    } else {
      update.password = formData.password;
      update.createBy = formData.createBy;
    }


    if (update.id ?? false) {
      callEdit(formData, managerToken)
        .then(res => {
          if(res.error) {
            snackbarError(t('manager.snackbar.managerUpdateFailed'));;
            console.error(res.error);
            Object.entries(res.errorDetails).forEach(([key, errors]) => {
              errors.forEach((error) => {
                snackbarError(error);
              })
            });
            return;
          }
          snackbarSuccess(t('manager.snackbar.managerUpdateSuccess'));
        });
    } else {
      callCreate(update, managerToken)
        .then(res => {
          if(res.error) {
            snackbarError(t('manager.snackbar.managerUpdateFailed'));;
            console.error(res.error);
            Object.entries(res.errorDetails).forEach(([key, errors]) => {
              errors.forEach((error) => {
                snackbarError(error);
              })
            });
            return;
          }
          if (res.payload?.id) setId(res.payload.id);
          snackbarSuccess(t('manager.snackbar.managerCreateSuccess'));
        });
    }
  }

  const [resetFormData, setResetFormData] = useState({});
  useEffect(() => {
    if (id) {
      setResetFormData({
        id: id,
        password: password ?? '',
        confirmPassword: confirmPassword ?? '',
      });
    } else {
      setResetFormData({});
    }

  }, [id, password, confirmPassword]);

  const {validateForm: validateResetForm, clearFormErrors: clearResetFormError, formErrors: resetFormErrors} = useValidator(resetFormData, resetUserPasswordSchema);
  useEffect(() => {
    const formErrorsValues = Object.values(resetFormErrors);
    formErrorsValues.forEach((error) => {
      snackbarError(t(error));
    })
  },[resetFormErrors]);

  const passwordErrors = useMemo(() => {
    return mergeDeep(formErrors, resetFormErrors);
  }, [formErrors, resetFormErrors])

  const resetPassword = () => {
    clearFormErrors();
    clearResetFormError();
    if(!validateResetForm()) {
      return;
    }

    const update = {
      managerId: resetFormData.id,
      password: resetFormData.password,
    };

    callResetPassword(update, managerToken)
      .then(res => {
        if(res.error) {
          snackbarError(t('manager.snackbar.managerResetPasswordFailed'));
          console.error(res.error);
          Object.entries(res.errorDetails).forEach(([key, errors]) => {
            errors.forEach((error) => {
              snackbarError(error);
            })
          });
          return;
        }
        snackbarSuccess(t('manager.snackbar.managerResetPasswordSuccess'));
      });
  }

  const columns = [
    {
      label: t('manager.stores.columns.name'),
        property: 'name',
    },
  ]

  return (
    <Form>
      {
        isLoadingAllStores
        ? <Spinner animation="border" role="status" size="xxl" />
        : (
          <>
            <ManagerCard title={t('manager.managers.edit.titleGeneral')}>
              {
                isLoadingCreate || isLoadingEdit || isLoadingReset
                  ? <Spinner animation="border" role="status" size="xxl" />
                  : (
                    <>
                      <Row>
                        <Col >
                          <Form.Group className="mb-3">
                            <Form.Label>{t('manager.managers.edit.fields.username')}</Form.Label>
                            <Form.Control
                              className={formErrors?.username ? 'is-invalid' : ''}
                              type="text"
                              placeholder={t('manager.managers.edit.fields.username')}
                              value={username}
                              onChange={basicOnChange(setUsername)}
                            />
                            {formErrors?.username ? <Form.Control.Feedback type="invalid">{t(formErrors?.username)}</Form.Control.Feedback> : <></>}
                          </Form.Group>

                          <Form.Check
                            type="switch"
                            label={t('manager.managers.edit.fields.isAdmin')}
                            id="manager-isAdmin"
                            checked={isAdmin}
                            onChange={basicOnChange(setIsAdmin)}
                          />
                        </Col>
                        <Col>
                          {
                            data?.id && !(manager?.isAdmin ?? false)
                              ? <></>
                              : (
                                <>
                                  <Form.Group className="mb-3">
                                    <Form.Label>{t('manager.managers.edit.fields.password')}</Form.Label>
                                    <Form.Control
                                      className={passwordErrors?.password ? 'is-invalid' : ''}
                                      type="password"
                                      placeholder={t('manager.managers.edit.fields.password')}
                                      onChange={basicOnChange(setPassword)}
                                    />
                                    {passwordErrors?.password ? <Form.Control.Feedback type="invalid">{t(passwordErrors?.password)}</Form.Control.Feedback> : <></>}
                                  </Form.Group>

                                  <Form.Group className="mb-3">
                                    <Form.Label>{t('manager.managers.edit.fields.confirmPassword')}</Form.Label>
                                    <Form.Control
                                      className={passwordErrors?.confirmPassword ? 'is-invalid' : ''}
                                      type="password"
                                      placeholder={t('manager.managers.edit.fields.confirmPassword')}
                                      onChange={basicOnChange(setConfirmPassword)}
                                    />
                                    {passwordErrors?.confirmPassword ? <Form.Control.Feedback type="invalid">{t(passwordErrors?.confirmPassword)}</Form.Control.Feedback> : <></>}
                                  </Form.Group>
                                  {
                                    !(manager?.isAdmin ?? false) || !data?.id
                                    ? <></>
                                    : <a className="link-danger" onClick={resetPassword}>{t('manager.managers.resetPassword')}</a>
                                  }
                                </>
                              )
                          }
                        </Col>
                      </Row>
                    </>
                  )
              }
              </ManagerCard>

              <ManagerCard title={t('manager.managers.edit.titleStores')}>
              {
                isLoadingCreate || isLoadingEdit || isLoadingReset
                ? <Spinner animation="border" role="status" size="xxl" />
                : (
                  <>
                    {formErrors?.stores ? <Form.Control.Feedback type="invalid" style={{display:'block'}}>{t(formErrors?.stores)}</Form.Control.Feedback> : <></>}
                    <ManagerDynamicList placeholder={t('manager.managers.edit.fields.stores')} setData={setStores} selection={stores ?? []} options={availableStores ?? []}  columns={columns} isAdmin={manager?.isAdmin ?? false} actionsInFront={true}/>
                  </>
                  )
              }
              </ManagerCard>

              {
                isLoadingCreate || isLoadingEdit || isLoadingReset
                ? <></>
                : (
                <div className="controls">
                  <Button variant="light" onClick={stepBack}>{t('cancel')}</Button>
                  <Button variant="primary" onClick={save} className="ms-auto">{t('save')}</Button>
                </div>
                )
              }
          </>
          )
      }
    </Form>
  );
}

export default ManagerManagerEdit;