import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {useContext, useState} from 'react';
import {ManagerContext} from '@components/manager/ManagerContainer';
import ManagerCard from '@components/manager/ManagerCard';
import Flashbag from '@components/Flashbag';
import ManagerTable from '@components/manager/ManagerTable';
import {deleteManager, deleteSalesman, fetchManagerList} from '@hooks/managerApi';
import {Spinner} from 'react-bootstrap';
import {CheckCircleFill, XCircleFill} from 'react-bootstrap-icons';
import {snackbarError, snackbarSuccess} from '@helper/snackbarHelper';

const ManagerManagerList = () => {
  const {manager, managerToken} = useContext(ManagerContext);
  const [tableDataLoaded, setTableDataLoaded] = useState(false);

  const handleDataLoaded= (value) => {
    setTableDataLoaded(value);
  };

  const {callApi: callDeleteManager, states: getState} = deleteManager();
  const {response, isLoading: isDeleting} = getState;


  const {t} = useTranslation();

  const columns = [
    {
      label: t('manager.managers.columns.username'),
      property: 'username',
    },
    {
      label: t('manager.managers.columns.isAdmin'),
      property: (data) => {
        return (
          data?.isAdmin ?? false
          ? <CheckCircleFill className="success" size="30"/>
          : <XCircleFill className="danger" size="30"/>
        );
      },
      adminOnly: true,
    },
    {
      label: t('manager.managers.columns.numberStores'),
      property: (data) => {
        return data?.stores?.length ?? 0;
      },
      adminOnly: true,
    },
  ];

  const deleteAction = {
    delete: {
      action: (id) => {
        callDeleteManager({Id: id}, managerToken)
          .then(res => {
            if(!res.error) {
              snackbarSuccess(t('manager.snackbar.deleteManagerSuccess'));
              handleDataLoaded(false);
              return;
            }
            snackbarError(t('manager.snackbar.deleteManagerFailed'));
            if (res.error) console.error(res.error);
          });
      },
      confirmModal: {
        title: t('manager.managers.confirmModal.delete.title'),
        content: t('manager.managers.confirmModal.delete.content'),
        btnText: t('manager.managers.confirmModal.delete.btnText'),
        btnVariant: "danger",
      }
    }
  }

  return (
    <ManagerCard title={t('manager.managers.title')}>
      {
        (!manager)
        ? <Spinner animation="border" role="status" size="xxl" />
        : (
            <>
              {
                (manager?.isAdmin ?? false)
                ? <ManagerTable fetchApi={fetchManagerList} apiResponseProperty="managers" authToken={managerToken} columns={columns} rowActions={deleteAction} isAdmin={manager.isAdmin ?? false} loaded={tableDataLoaded} handleLoaded={handleDataLoaded}/>
                : <Flashbag type="error">{t('manager.unauthorized')}</Flashbag>
              }
            </>
          )
      }
    </ManagerCard>
  );
}

export default ManagerManagerList;