import * as React from 'react';
import ManagerContainer from '@components/manager/ManagerContainer';
import ManagerStoresList from '@components/manager/ManagerStoresList';
const ManagerStoresPage = () => {
  return (
    <ManagerContainer>
      <ManagerStoresList />
    </ManagerContainer>
  );
}

export default ManagerStoresPage;