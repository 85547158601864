import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  list: {},
  commission: {},
}

export const commissionsSlice = createSlice({
  name: 'commissionsSlice',
  initialState,
  reducers: {
    setList: (state, action) => {
      state.list = action.payload;
    },
    setCommission: (state, action) => {
      state.commission = action.payload;
    },
  },
})

export const { setList, setCommission } = commissionsSlice.actions

export default commissionsSlice.reducer