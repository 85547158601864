import * as React from 'react';
import { useSelector } from "react-redux";
import classNames from 'classnames';
import {ReactComponent as ReactLogo} from '@assets/CTW-BWT-Logo.svg';
import CommissionHeader from '@components/commission/CommissionHeader';
import {Col, Container, Row} from 'react-bootstrap';

const Header = () => {
  const headerType = useSelector(state => state.layout.headerType);
  const headerBackground = useSelector(state => state.layout.headerBackground);

  let headerClasses = '';
  let containerClasses = '';
  switch(headerType) {
    case 'fixed-top':
      headerClasses = classNames({
        'fixed-top': true,
      })
      break;
    default:
      containerClasses = classNames({
        'container': true,
      })
      break;
  }

  return (
      headerType === 'none'
      ? <></>
      : (
        <header className={headerClasses} style={{
          background: headerBackground,
        }}>
          <div className={containerClasses}>
            <div className="logo right">
              <ReactLogo />
            </div>
          </div>
          <Row>
            <Col>
              <Container>
                <CommissionHeader />
              </Container>
            </Col>
          </Row>
        </header>
      )
  );
}

export default Header;