import * as React from 'react';
import {useDispatch,} from 'react-redux';
import {createContext, useEffect, useState} from 'react';
import {hideBackground, showBackground} from '@redux/slices/backgroundSlice';
import ManagerNavigation from '@components/manager/ManagerNavigation';
import {setHeaderType} from '@redux/slices/layoutSlice';
import {Container, Spinner} from 'react-bootstrap';
import ManagerLogin from '@components/manager/ManagerLogin';
import {persistInLocalStorage, restoreFromLocalStorage} from '@helper/persistHelper';
import classNames from 'classnames';
import {SnackbarProvider} from 'notistack';
import {getManagerInformation} from '@hooks/managerApi';
import ManagerFooter from '@components/manager/ManagerFooter';


export const ManagerContext = createContext();
const ManagerContainer = ({children}) => {
  const [restored, setRestored] = useState(false);
  const [manager, setManager] = useState(null);
  const [managerToken, setManagerToken] = useState(null);

  const {callApi: callManagerInfo, states: getInfoState} = getManagerInformation();
  const {responseInfo, isLoadingManagerInfo} = getInfoState;

  // restore login from
  if (!restored) {
    setRestored(true);
    const token = restoreFromLocalStorage('managerToken');
    setManagerToken(token);
  }
  useEffect(() => {
    if (managerToken) {
      // try to load user information
      callManagerInfo(null, managerToken)
        .then(res => {
          let manager = null;
          if(!res.error) {
            manager = res.payload ?? null;
          } else {
            console.error(res.error);
          }
          setManager(manager);
          if (manager === null) {
            setManagerToken(null);          }
        });
    }
    persistInLocalStorage('managerToken', managerToken);
  }, [managerToken]);

  const loggedIn = managerToken !== null;
  const dispatch = useDispatch();
  useEffect(() => {
    if (loggedIn) {
      dispatch(hideBackground());
      dispatch(setHeaderType('none'));
    } else {
      dispatch(showBackground());
      dispatch(setHeaderType('fixed-top'));
    }
  }, [loggedIn]);

  const classes = classNames({
    'scm-administration': true,
    background: loggedIn,
  })

  return (
    <div className={classes}>
      <SnackbarProvider maxSnack={5} anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}>
        <ManagerContext.Provider value={{manager, managerToken, loggedIn}}>
          <ManagerNavigation />
          <Container className="scm-content">
            {
              loggedIn
                ? children
                : <ManagerLogin setManager={setManager} setManagerToken={setManagerToken} />
            }
          </Container>
          <ManagerFooter managerToken={managerToken} setManager={setManager} setManagerToken={setManagerToken} />
        </ManagerContext.Provider>
      </SnackbarProvider>
    </div>
  );
}

export default ManagerContainer;