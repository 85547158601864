import * as React from 'react';
import {useSelector} from 'react-redux';
import {useEffect, useMemo} from 'react';
import {Trans} from 'react-i18next';
import {isOrderPending} from '@helper/commissionHelper';
import Flashbag from '@components/Flashbag';
import {ExclamationCircleFill} from 'react-bootstrap-icons';


const CommissionInfos = ({children}) => {
  const commission = useSelector(state => state.commissions.commission);

  return (
    <div className={"commission-infos"}>
      {
        isOrderPending(commission)
        ? (
            <Flashbag type={"notice"} bold={true} textAlign={"center"}>
              <p className="icon">
                <ExclamationCircleFill color="var(--bs-danger)" size={"40px"}/>
              </p>
              <p>
                <Trans i18nKey="commission.order.pending" components={[<b />]}/>
              </p>
            </Flashbag>
          )
        : <> </>
      }
    </div>
  );
}

export default CommissionInfos;