import {useCallback, useState} from 'react';
import {setNestedProp} from '@helper/objectHelper';

export const useValidator = (formData, formSchema) => {
  const [formErrors, setFormErrors] = useState({});

  const validateForm = useCallback(() => {
    const parseResult = formSchema.safeParse(formData);
    
    const newErrorObj = {};
    
    if(parseResult.error) {
      const issues = parseResult.error.issues;

      issues.forEach((issue) => {
        setNestedProp(newErrorObj, issue.message, issue.path)
      })
    }

    setFormErrors(newErrorObj);
    return parseResult.success;
  }, [formData, formSchema]);
  
  const clearFormErrors = useCallback(() => {
    setFormErrors({});
  }, []);
  
  return {
    validateForm,
    clearFormErrors,
    formErrors
  }
}