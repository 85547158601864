import $ from 'jquery';

export const autoScrollTopBottom = (selector, timeoutScroll, timeoutTop, timeoutBottom, step) => {
  if (typeof selector === 'undefined') return;
  if (typeof timeoutScroll === 'undefined') timeoutScroll = 50;
  if (typeof timeoutTop === 'undefined') timeoutTop = 3000;
  if (typeof timeoutBottom === 'undefined') timeoutBottom = 500;
  if (typeof step === 'undefined') step = 2;

  let up = false;
  let lastPosition;

  const scroll = () => {
    let scrollTimeout = timeoutScroll;
    const $containerList = $(selector);
    $containerList.each(() => {
      const $container = $(this);
      const scrollHeight = $container[0].scrollHeight;
      const innerHeight = $container.innerHeight();

      if (innerHeight < scrollHeight) {
        let position = $container.scrollTop();

        if(position === lastPosition) up = !up;

        lastPosition = position;
        position += up ? +step : -step;

        if (position < 0) {
          position = 0;
          scrollTimeout = timeoutTop;
        } else if ((position+innerHeight) > scrollHeight) {
          position = scrollHeight - innerHeight;
          scrollTimeout = timeoutBottom;
        }
        $container.scrollTop(position);
      }
    });
    const timer = setTimeout(scroll, scrollTimeout);
  }
  const timer = setTimeout(scroll, timeoutTop);
};

export const updateScrollPagesConfig = (flipConfig) => {
  window.autoScrollPages = {...window.autoScrollPages, ...flipConfig};

  if (typeof window.autoScrollPages.pageSize === 'undefined') window.autoScrollPages.pageSize = 3;
  if (typeof window.autoScrollPages.flipTimeout === 'undefined') window.autoScrollPages.flipTimeout = 7000;
  if (typeof window.autoScrollPages.flipDuration === 'undefined') window.autoScrollPages.flipDuration = 1000;
}


export const autoScrollPages = (selector, flipConfig) => {
  if (typeof selector === 'undefined') return;

  window.autoScrollPages = {
    selector: selector,
  }
  updateScrollPagesConfig(flipConfig);

  const filterOnPageSize = function() {
    const $container = $(this);
    return $container.not(':visible').length || $container.children().length > window.autoScrollPages.pageSize;
  }

  const flip = () => {
    const pageSize = window.autoScrollPages.pageSize;
    const flipTimeout = window.autoScrollPages.flipTimeout;
    const flipDuration = window.autoScrollPages.flipDuration;

    const $containerList = $(window.autoScrollPages.selector);

    const completeShow = function() {
      const timer = setTimeout(flip, flipTimeout);
    }

    const completeHide = function() {
      $containerList.each(function() {
        const $container = $(this);
        const elements = $container.children();
        let nextPage = $container.data('nextPage') ?? null;
        if (elements.length > pageSize || (nextPage && nextPage > 0)) {
          nextPage = nextPage ?? 1;

          const maxPage = Math.ceil(elements.length/pageSize)
          if (nextPage >= maxPage) {
            nextPage = 0;
          }

          let startIndex = nextPage * pageSize;

          // next page should show the first not visible element
          const lastVisibleElement = elements.filter('.show:last');
          if (lastVisibleElement) {
            const index = elements.index(lastVisibleElement);
            if (index >= (elements.length-1)) {
              nextPage = 0;
              startIndex = 0;
            } else if (index >= 0) {
              startIndex = index+1;
              nextPage = Math.ceil(startIndex/pageSize);
            }
          } else {
            nextPage = 0;
            startIndex = 0;
          }

          const lastIndex = startIndex + pageSize;

          elements.removeClass('show');
          elements.slice(startIndex, lastIndex).addClass('show');

          if (elements.length > pageSize) {
            nextPage++;
          }
          $container.data('nextPage', nextPage);
        } else {
          fadeInElementsOnCurrentPage(elements, pageSize, flipDuration);
        }
      });

      $containerList.filter(filterOnPageSize).animate(
        {
          opacity: 1
        },{
          duration: flipDuration,
        }
      ).promise().done(completeShow);
    }


    $containerList.filter(filterOnPageSize).animate(
      {
        opacity: 0
      },{
        duration: flipDuration,
      }
    ).promise().done(completeHide);
  }

  const timer = setTimeout(flip, window.autoScrollPages.flipTimeout);
}

export const fadeInElementsOnCurrentPage = (elements, pageSize, flipDuration) => {
  // skip if no hidden elements found
  if (elements.filter(':not(.show)').length === 0) return;
  // already more or equal pagesize visible
  if (elements.filter('.show').length >= pageSize) return;

  // next page should show the first not visible element
  let startIndex = 0;
  const firstVisibleElement = elements.filter('.show:first');
  if (firstVisibleElement) {
    const index = elements.index(firstVisibleElement);
    if (index >= 0) {
      startIndex = index;
    }
  }
  const lastIndex = startIndex + pageSize;

  const hiddenElements = elements.slice(startIndex, lastIndex).filter(':not(.show)');
  hiddenElements
    .css({
      opacity: 0,
    })
    .addClass('show')
    .animate(
      {
        opacity: 1,
      },{
        duration: flipDuration,
      }
    ).promise().done(() => {
    hiddenElements.css({});
  });
}