import * as React from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {updateLanguageByParam} from '@helper/languageHelper';
import {useTranslation} from 'react-i18next';
import {Col, Container, Row, Spinner} from 'react-bootstrap';
import {useCallback, useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {hideBackground, showBackground} from '@redux/slices/backgroundSlice';
import {loadCommission} from '@hooks/api';
import {setCommission, setList} from '@redux/slices/commissionsSlice';
import {
  hideCommissionState,
  resetHeader,
  setHeaderBackground,
  setHeaderType,
  showCommissionState
} from '@redux/slices/layoutSlice';
import CommissionLineItems from '@components/commission/CommissionLineItems';
import CommissionReview from '@components/commission/CommissionReview';
import CommissionNewsletter from '@components/commission/CommissionNewsletter';
import $ from 'jquery';
import CommissionStateList from '@components/commission/CommissionStateList';
import {isObjectEmpty} from '@helper/objectHelper';
import CommissionFreeCoffee from '@components/commission/CommissionFreeCoffee';
import CommissionInfos from '@components/commission/CommissionInfos';

const CommissionDetailPage = ({children}) => {
  const config = useSelector(state => state.config.commissionDetails);

  const {langCode} = useParams();
  const {i18n} = useTranslation();
  useEffect(() => {
    updateLanguageByParam({i18n, langCode});
  }, [langCode,i18n]);

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(hideBackground());
    dispatch(resetHeader());
    dispatch(setHeaderBackground('#FFFFFF'));
  }, []);

  const commission = useSelector(state => state.commissions.commission);

  const {commissionId} = useParams();
  const {t} = useTranslation();

  const navigate = useNavigate();
  const returnToHomepage = () => {
    navigate('/');
  }

  const {callApi: callLoadCommission, states: getCommissionState} = loadCommission();
  const {response, isLoading} = getCommissionState;
  const timeoutRef = useRef(null);

  const handleLoad = useCallback((abortSignal) => {
    if (commissionId) {
      const data = {
        uuid: commissionId
      };

      callLoadCommission(data, {signal: abortSignal})
        .then(res => {
          if(!res.error) {
            timeoutRef.current = setTimeout(() => {
              handleLoad(abortSignal)
            }, config.api.pullInterval);
            return;
          }

          if (res.error.name !== 'AbortError') {
            console.error(res.error);
          }
        });
    }
  }, [callLoadCommission, commissionId]);

  useEffect(() => {
    const abortController = new AbortController();
    handleLoad(abortController.signal);
    return () => {
      clearTimeout(timeoutRef.current);
      abortController.abort();
    }
  }, [handleLoad]);


  useEffect(() => {
    if (response && !response.error) {
      dispatch(setHeaderType('fixed-top'));
      dispatch(setCommission(response.payload));
    } else {
      dispatch(setCommission({}));
    }
  }, [response]);

  useEffect(() => {
    if (commission && !isObjectEmpty(commission)) {
      dispatch(showCommissionState());
    } else {
      dispatch(hideCommissionState());
    }
  }, [commission]);

  let route = useSelector(state => state.config.urls.commissionDetailPage);
  useEffect(() => {
    if (commission?.language && commission.language !== i18n.language) {
      route = route.replace(':langCode', commission.language).replace(':commissionId', commissionId);
      navigate(route);
    }
  }, [commission, route]);

  useEffect(() => {
    $(window).scroll(() => {
      if ($(window).scrollTop() > 0) {
        $('header').addClass('scrolled');
      } else {
        $('header').removeClass('scrolled');
      }
    });
  },[]);

  return (
    <Container className="dark-font mt-0">
      <Row>
        <Col>
          {
            isObjectEmpty(commission)
            ? (
              <>
                {
                  isLoading
                  ? <Spinner animation="border" role="status" size="xxl" />
                  : (
                    <>
                      <h1>{t('commission.notfound.title')}</h1>
                      <p>{t('commission.notfound.description')}</p>
                      <p><a className="bwtBtn" onClick={returnToHomepage}>{t('commission.notfound.backlink')}</a></p>
                    </>
                  )
                }
              </>
            )
            : (
            <div className="commission-details">
              <CommissionStateList />
              <CommissionInfos />
              <CommissionLineItems />
              <CommissionNewsletter />
              <CommissionFreeCoffee />
              <CommissionReview isPrimary={commission?.commissionDetail?.subscribedNewsletter ?? false} />
            </div>
            )
          }
        </Col>
      </Row>
    </Container>
  );
}

export default CommissionDetailPage;