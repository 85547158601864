import { createSlice } from '@reduxjs/toolkit'
import appConfig from '@src/config/config';

const initialState = {...appConfig};

export const configSlice = createSlice({
  name: 'layoutSlice',
  initialState,
  reducers: {
    setApiPullInterval: (state, action) => {
      state.commissionList.api.pullInterval = action.payload;
      state.commissionDetails.api.pullInterval = action.payload;
    },
    setFlipInterval: (state, action) => {
      state.commissionList.flip.interval = action.payload;
    },
    setFlipDuration: (state, action) => {
      state.commissionList.flip.duration = action.payload;
    },
    setFlipPageSize: (state, action) => {
      state.commissionList.flip.pageSize = action.payload;
    },
  },
})

export const { setApiPullInterval, setFlipInterval, setFlipDuration, setFlipPageSize } = configSlice.actions

export default configSlice.reducer