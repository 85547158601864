import * as React from 'react';
import {useParams, useSearchParams} from 'react-router-dom';
import {showBackground} from '@redux/slices/backgroundSlice';
import {resetHeader, setHeaderType} from '@redux/slices/layoutSlice';
import {useDispatch} from "react-redux";
import {useTranslation} from 'react-i18next';
import CommissionListDetail from '@components/dashboard/CommissionListDetail';
import {updateLanguageByParam} from '@helper/languageHelper';
import {useEffect} from 'react';
import {setFlipPageSize} from '@redux/slices/configSlice';
import CommissionListMinimal from '@components/dashboard/CommissionListMinimal';
const DashboardPage = () => {
  const {langCode} = useParams();
  const {i18n} = useTranslation();
  useEffect(() => {
    updateLanguageByParam({i18n, langCode});
  }, [langCode,i18n]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(showBackground());
    dispatch(resetHeader());
    dispatch(setHeaderType('fixed-top'));
  }, []);

  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    const viewport = searchParams.get('viewport');
    const appRoot = document.querySelector('#root');
    if (appRoot && viewport) {
      appRoot.classList.add('dashboard-'+viewport);

      // check if we need to change the config
      switch(viewport) {
        case 'fullsize-portrait':
          dispatch(setFlipPageSize(4));
          break;
        default:
          break;
      }
    }
  }, [searchParams]);

  const {storeId, viewMode} = useParams();
  const {t} = useTranslation();

  return (
    <div className="dashboard">
      <div className="headline">
        <h1>{t('dashboard.title')}</h1>
        <h2>{t('dashboard.subtitle')}</h2>
      </div>
      { viewMode !== 'minimal'
        ? <CommissionListDetail storeId={storeId} />
        : <CommissionListMinimal storeId={storeId} />
      }
    </div>
  );
}

export default DashboardPage;