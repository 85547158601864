import {useCallback, useEffect, useMemo, useState} from 'react';
import { v4 as uuid } from 'uuid';
import {useNavigate} from 'react-router-dom';
import {Row, Form, InputGroup, Button, FormControl, Col} from 'react-bootstrap';
import {QrCodeScan} from 'react-bootstrap-icons';
import {Html5Qrcode} from 'html5-qrcode';


const QrCodeScanner = ({btnText, baseRoute, routeParam, modifyScanResult}) => {
  const [code, setCode] = useState('');
  const [scanner, setScanner] = useState(null);
  const [scannerId, setScannerId] = useState(null);

  if (scannerId === null) {
    const readerId = 'qrReader_'+uuid();
    setScannerId(readerId);
  }

  const scannerConfig = { fps: 10, qrbox: { width: 250, height: 250 }, disableFlip: false };

  useEffect(() => {
    setScanner(new Html5Qrcode(scannerId));
  }, []);


  const onNewScanResult = (decodedText, decodedResult) => {
    let result = decodedText;
    if (modifyScanResult && typeof modifyScanResult === 'function') {
      result = modifyScanResult({decodedText, decodedResult});
    }
    setCode(result);
    scanner.stop();
  }
  const useQRScanner = (event) => {
    if (scanner === null) return;
    if (scanner.isScanning) return;
    scanner.start({ facingMode: "environment" },  scannerConfig, onNewScanResult);
  }

  const updateCode = (event) => {
    setCode(event.target.value.toString().toLowerCase());
  }

  const validateCode = (code) => {
    return /^[a-z0-9]{32}$/g.test(code);
  }

  const navigate = useNavigate();
  const routeChange = () => {
    if (!validateCode(code)) return;
    const route = baseRoute.replace(':'+routeParam, code);
    navigate(route);
  }

  useEffect(() => {
    routeChange();
  }, [code]);

  return (
    <>
      <Row>
        <Form className="col col-md-6 col-lg-4" onSubmit={(e) => { e.preventDefault(); return false; }}>
          <div id={scannerId} className="qr-reader"></div>
          <InputGroup>
            <FormControl type="text" onChange={updateCode} value={code} />
            <InputGroup.Text onClick={useQRScanner}>
              <QrCodeScan size="2rem"/>
            </InputGroup.Text>
          </InputGroup>
          <Button className="col-12" onClick={code ? routeChange : useQRScanner}>
            {btnText}
          </Button>
        </Form>
      </Row>
    </>
  );
}

export default QrCodeScanner;