import {useMemo, forwardRef} from 'react';
import {useSelector} from 'react-redux';
import classNames from 'classnames';
import {useTranslation} from 'react-i18next';
import {isInProgress,isReadyForPickup,isPaid,isCompleted, isCancelled} from '@helper/commissionHelper';

// wrap in forwardRef otherwise react-flip-move would not work
const CommissionListItem = forwardRef(({uniqueId}, ref) => {
  const {t} = useTranslation();
  const commissionsList = useSelector(state => state.commissions.list);
  const commission = commissionsList[uniqueId] ?? null;

  const type = useMemo(() => {
    if (commission === null) return '';
    const states = commission.commissionState;

    if (isInProgress(states) && !isReadyForPickup(states) && !isCompleted(states) && !isCancelled(states)) {
      return 'inProgress';
    }

    if (isInProgress(states) && isReadyForPickup(states) && !isCompleted(states) && !isCancelled(states)) {
      return 'readyForPickup';
    }
  }, [commissionsList, uniqueId, commission]);

  const classes = useMemo(() => {
    return classNames({
      'commission-list-item': true,
      'inProgress': type === 'inProgress',
      'readyForPickup': type === 'readyForPickup',
    })
  }, [type]);

  return (
        !commission
        ? <></>
        : (
            <li ref={ref} className={classes}>
              <span className="prefix">{t('commission.prefix')}&nbsp;</span>
              <b>#{commission.number.toString().slice(-2)}</b>&nbsp;
              <span className="separator">-&nbsp;</span>
              <span className="status">{t('commission.states.'+type)}</span>
            </li>
          )
  );
});

export default CommissionListItem;