import appConfig from '@src/config/config';


export const randomIntFromInterval = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export const generateEAN13Code = (prefix = appConfig.manager.eanPrefix) => {
  let digits = 12;
  let code = prefix.toString();

  digits -= code.length;

  for(let i = 0; i < digits; i++) {
    code += randomIntFromInterval(0,9);
  }

  // EAN 13 checksum calculation
  let crc = 0;
  for (let i = 0; i < code.length; i++) {
    if (i%2) {
      crc += 3*code[i];
    } else {
      crc += 1*code[i]
    }
  }

  return code + (10 - crc%10).toString().slice(-1);
}

export const validateEAN13 = (ean) => {
  if (ean.length != 13) return false;

  let code = ean.slice(0,-1);
  let checkSum = ean.slice(-1);

  let crc = 0;
  for (let i=0; i< code.length; i++) {
    if (i%2) {
      crc += 3*code[i];
    } else {
      crc += 1*code[i]
    }
  }
  const calculatedChecksum = (10 - crc%10).toString().slice(-1);
  return checkSum === calculatedChecksum;
}

// equivalent to php dirname
export const dirname = (path) => {
  //  discuss at: https://locutus.io/php/dirname/
  // original by: Ozh
  // improved by: XoraX (https://www.xorax.info)
  //   example 1: dirname('/etc/passwd')
  //   returns 1: '/etc'
  //   example 2: dirname('c:/Temp/x')
  //   returns 2: 'c:/Temp'
  //   example 3: dirname('/dir/test/')
  //   returns 3: '/dir'
  return path.replace(/\\/g, '/')
    .replace(/\/[^/]*\/?$/, '')
}


export const basicOnChange = (callback) => {
  return (e) => {
    switch(e.target.type) {
      case 'checkbox':
      case 'radio':
        callback(e.target.checked);
        break;
      default:
        callback(e.target.value);
        break;
    }
  }
}