import * as React from 'react';
import ManagerContainer from '@components/manager/ManagerContainer';
import ManagerManagerEdit from '@components/manager/ManagerManagerEdit';
const ManagerManagerEditPage = ({create}) => {
  return (
    <ManagerContainer>
      <ManagerManagerEdit />
    </ManagerContainer>
  );
}

export default ManagerManagerEditPage;