import * as React from 'react';
import ManagerTable from '@components/manager/ManagerTable';
import {Accordion, Card, Col, Form, InputGroup} from 'react-bootstrap';
import {basicOnChange} from '@helper/generalHelper';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import classNames from 'classnames';
import {Plus} from 'react-bootstrap-icons';


const ManagerDynamicList = ({placeholder, setData, selection, options, columns, isAdmin, listId='dynamicList', valueProperty='id', displayProperty='name', minFilterLength=3, filterProperties=['name']}) => {
  const {t} = useTranslation();

  const [filter, setFilter] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);

  const filterValid = filter.length >= minFilterLength;

  const headerActions = {
    create: null
  }
  const rowActions = {
    edit: null,
    delete: {
      action: (id) => {
        const newData = selection.filter((item) => item.id !== id);
        setData(newData);
      }
    }
  }

  useEffect(() => {
    let tmpOptions;
    if (filterValid) {
      // first remove already selected options
      tmpOptions = options.filter((item) => {
        return !selection.find((selected) => item.id === selected.id);
      });

      tmpOptions = tmpOptions.filter((item) => {
        let found = false;

        filterProperties.forEach((prop) => {
          found |= item[prop].toLowerCase().includes(filter.toLowerCase());
        });

        return found;
      });


    } else {
      tmpOptions = [];
    }
    setFilteredOptions(tmpOptions);
  },[filter, selection, options]);

  const addItem = (item) => {
    const data = [...selection, item];
    setData(data);
  }

  const accordionClasses = classNames({
    'dynamic-list': true,
    'collapsed': !filterValid,
  });


  return (
    <>
      <Col xs={12} sm={6}>
        <Form.Group className="mb-3">
          <Accordion className={accordionClasses}>
            <Card>
              <Card.Header>
                <Form.Control type="text" placeholder={placeholder} onChange={basicOnChange(setFilter)}/>
              </Card.Header>
              <Accordion.Collapse>
                {
                  filteredOptions.length
                    ? (
                      <ul>
                        {
                          filteredOptions.map((item, key) => {
                            return (
                              <li key={key} value={item[valueProperty]}>
                                <Plus size={30} onClick={() => {addItem(item)}} />
                                <span>
                                  {
                                    typeof displayProperty === 'function'
                                    ? displayProperty(item)
                                    : item[displayProperty]
                                  }
                                </span>
                              </li>
                            )
                          })
                        }
                      </ul>
                      )
                    : <p>{t('emptySearchResult')}</p>
                }
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Form.Group>
      </Col>
      <ManagerTable data={selection} columns={columns} rowActions={rowActions} headerActions={headerActions} isAdmin={isAdmin} actionsInFront={true}/>
    </>
  );
}

export default ManagerDynamicList;