import * as React from 'react';
import ManagerContainer from '@components/manager/ManagerContainer';
import ManagerManagerList from '@components/manager/ManagerManagerList';
import ManagerSalesmenList from '@components/manager/ManagerSalesmenList';
const ManagerSalesmenPage = () => {
  return (
    <ManagerContainer>
      <ManagerSalesmenList />
    </ManagerContainer>
  );
}

export default ManagerSalesmenPage;