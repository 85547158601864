import * as React from 'react';
import ManagerContainer from '@components/manager/ManagerContainer';
import ManagerSalesmanEdit from '@components/manager/ManagerSalesmanEdit';
const ManagerSalesmanEditPage = () => {
  return (
    <ManagerContainer>
      <ManagerSalesmanEdit />
    </ManagerContainer>
  );
}

export default ManagerSalesmanEditPage;