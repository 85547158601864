import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {useContext, useEffect, useMemo, useState} from 'react';
import {ManagerContext} from '@components/manager/ManagerContainer';
import {useLocation, useNavigate} from 'react-router-dom';
import {Button, Col, Form, Row, Spinner} from 'react-bootstrap';
import ManagerCard from '@components/manager/ManagerCard';
import {basicOnChange, dirname} from '@helper/generalHelper';
import {createStore, editStore, fetchManagerList, fetchSalesmenList, getAssignedStores} from '@hooks/managerApi';
import {snackbarError, snackbarSuccess} from '@helper/snackbarHelper';
import ManagerDynamicList from '@components/manager/ManagerDynamicList';
import {z} from 'zod';
import {useValidator} from '@hooks/useValidator';

const updateStoreSchema = z.object({
  name: z.string({required_error: 'manager.stores.edit.error.nameRequired'})
    .min(3, 'manager.stores.edit.error.nameToShort')
    .max(255, 'manager.stores.edit.error.nameToLong')
    .nonempty('manager.stores.edit.error.nameRequired'),
  address: z.string({required_error: 'manager.stores.edit.error.addressRequired'})
    .nonempty('manager.stores.edit.error.addressRequired'),
  zip: z.string({required_error: 'manager.stores.edit.error.zipRequired'})
    .nonempty('manager.stores.edit.error.zipRequired'),
  city: z.string({required_error: 'manager.stores.edit.error.cityRequired'})
    .nonempty('manager.stores.edit.error.cityRequired'),
  country: z.string({required_error: 'manager.stores.edit.error.countryRequired'})
    .nonempty('manager.stores.edit.error.countryRequired'),
  user: z.string({required_error: 'manager.stores.edit.error.createdByRequired'}),
  managers: z.array(z.any())
    .min(1, 'manager.stores.edit.error.noManagersSelected'),
});

const ManagerStoreEdit = () => {
  const {manager, managerToken} = useContext(ManagerContext);
  const {t} = useTranslation();

  const {state} = useLocation();
  const { data } = state ?? {};

  const [isManagerOfStore, setIsManagerOfStore] = useState(false);

  const [id, setId] = useState(null);
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [address2, setAddress2] = useState('');
  const [zip, setZip] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEMail] = useState('');
  const [website, setWebsite] = useState('');
  const [managers, setManagers] = useState([]);
  const [salesmen, setSalesmen] = useState([]);
  // set values to the fields
  useEffect(() => {
    if (data) {
      setId(data.id ?? null);
      setName(data.name ?? '');
      setAddress(data.address ?? '');
      setAddress2(data.address2 ?? '');
      setZip(data.zip ?? '');
      setCity(data.city ?? '');
      setCountry(data.country ?? '');
      setPhone(data.phone ?? '');
      setEMail(data.email ?? '');
      setWebsite(data.website ?? '');
      setManagers(data.managers ?? []);
      setSalesmen(data.salesmen ?? []);
    }
  }, [data]);

  const [formData, setFormData] = useState(null);
  useEffect(() => {
    const tmpFormData = {
      id: id,
      name: name,
      address: address,
      address2: address2 !== '' ? address2 : null,
      zip: zip,
      city: city,
      country: country,
      phone: phone !== '' ? phone : null,
      email: email !== '' ? email : null,
      website: website !== '' ? website : null,
      managers: managers.map((manager) => manager.id),
      salesmen: salesmen.map((salesman) => salesman.id),
      user: manager?.username ?? '',
    };

    if (id) {
      tmpFormData.id = id;
    }
    setFormData(tmpFormData);
  }, [manager, id, name, address, address2, zip, city, country, phone, email, website, managers, salesmen]);

  const formSchema = useMemo(() => {
    return updateStoreSchema;
  },[]);

  const {validateForm, clearFormErrors, formErrors} = useValidator(formData, formSchema);
  useEffect(() => {
    const formErrorsValues = Object.values(formErrors);
    formErrorsValues.forEach((error) => {
      snackbarError(t(error));
    })
  },[formErrors]);

  const [assignedStores, setAssignedStores] = useState(false);
  const [filter, setFilter] = useState(null);
  const {callApi: callGetAssignedStores, states: getStateAssignedStores} = getAssignedStores();
  const {response: responseGetAssignedStores, isLoading: isLoadingAssignedStores} = getStateAssignedStores;

  useEffect(() => {
    callGetAssignedStores(null, managerToken)
      .then(res => {
        if(res.error) {
          console.error(res.error);
          return;
        }
        let storeIds = res.payload.stores.map((store) => store.id);
        setAssignedStores(res.payload.stores);
        setFilter({storeIds: storeIds.join(',')});
      });
  }, [managerToken]);

  useEffect(() => {
    if (id) {
      if (Object.values(assignedStores).find((store) => store.id === id)) {
        setIsManagerOfStore(true);
      } else {
        setIsManagerOfStore(false);
      }
    }
  }, [assignedStores]);

  const {callApi: callGetAllManagers, states: getStateManagers} = fetchManagerList(null, manager?.isAdmin ?? false);
  const {response: responseGetAllManagers, isLoading: isLoadingAllManagers} = getStateManagers;
  const [availableManagers, setAvailableManagers] = useState([]);

  const {callApi: callGetAllSalesmen, states: getStateSalesmen} = fetchSalesmenList(null, manager?.isAdmin ?? false);
  const {response: responseGetAllSalesmen, isLoading: isLoadingAllSalesmen} = getStateSalesmen;
  const [availableSalesmen, setAvailableSalesmen] = useState([]);

  useEffect(() => {
    if (managerToken && (manager?.isAdmin ?? false)) {
      callGetAllManagers(null, managerToken)
        .then(res => {
          if (res.error) {
            console.error(res.error);
            return;
          }
          setAvailableManagers(res.payload.managers ?? []);
        })
    }
  }, [manager]);

  useEffect(() => {
    if (managerToken && ((manager?.isAdmin ?? false) || filter !== null)) {
      callGetAllSalesmen(filter, managerToken)
        .then(res => {
          if(res.error) {
            console.error(res.error);
            return;
          }
          setAvailableSalesmen(res.payload.salesmen ?? []);
        })
    }
  }, [manager, filter]);


  const location = useLocation();
  const navigate = useNavigate();
  const stepBack = () => {
    navigate(dirname(location.pathname));
  }

  const {callApi: callCreate, states: getStateCreate} = createStore();
  const {response: responseCreate, isLoading: isLoadingCreate} = getStateCreate;

  const {callApi: callEdit, states: getStateEdit} = editStore();
  const {response: responseEdit, isLoading: isLoadingEdit} = getStateEdit;

  const save = () => {
    clearFormErrors();
    if(!validateForm()) {
      return;
    }

    const update = {
      name: formData.name,
      address: formData.address,
      address2: formData.address2,
      zip: formData.zip,
      city: formData.city,
      country: formData.country,
      phone: formData.phone,
      email: formData.email,
      website: formData.website,
      managers: formData.managers,
      salesmen: formData.salesmen,
      user: formData.user,
    };

    if (id) {
      update.id = formData.id;
    }

    if (update.id ?? false) {
      callEdit(update, managerToken)
        .then(res => {
          if(res.error) {
            snackbarError(t('manager.snackbar.storeUpdateFailed'));;
            console.error(res.error);
            Object.entries(res.errorDetails).forEach(([key, errors]) => {
              errors.forEach((error) => {
                snackbarError(error);
              })
            });
            return;
          }
          snackbarSuccess(t('manager.snackbar.storeUpdateSuccess'));
        });
    } else {
      callCreate(update, managerToken)
        .then(res => {
          if(res.error) {
            snackbarError(t('manager.snackbar.storeUpdateFailed'));;
            console.error(res.error);
            Object.entries(res.errorDetails).forEach(([key, errors]) => {
              errors.forEach((error) => {
                snackbarError(error);
              })
            });
            return;
          }
          if (res.payload?.id) setId(res.payload.id);
          snackbarSuccess(t('manager.snackbar.storeCreateSuccess'));
        });
    }
  }

  const columnsManager = [
    {
      label: t('manager.managers.columns.username'),
      property: 'username',
    },
  ];

  const managerDisplay = (item) => {
    return `${item.username}`;
  }

  const columnsSalesmen = [
    {
      label: t('manager.salesmen.columns.lastname'),
      property: 'lastname',
    },
    {
      label: t('manager.salesmen.columns.firstname'),
      property: 'firstname',
    },
    {
      label: t('manager.salesmen.columns.ean'),
      property: 'ean',
    },
  ];

  const salesmenDisplay = (item) => {
    return `${item.firstname} ${item.lastname}`;
  }

  return (
    <Form>
      {
        isLoadingAllManagers || isLoadingAllSalesmen
          ? <Spinner animation="border" role="status" size="xxl" />
          : (
            <>
              <ManagerCard title={t('manager.stores.edit.titleGeneral')}>
                {
                  isLoadingCreate || isLoadingEdit
                    ? <Spinner animation="border" role="status" size="xxl" />
                    : (
                      <>
                        <Row>
                          <Col xs={12} sm={6}>
                            <Form.Group className="mb-3">
                              <Form.Label>{t('manager.stores.edit.fields.name')}</Form.Label>
                              <Form.Control
                                className={formErrors?.name ? 'is-invalid' : ''}
                                type="text"
                                placeholder={t('manager.stores.edit.fields.name')}
                                value={name}
                                onChange={basicOnChange(setName)}
                              />
                              {formErrors?.name ? <Form.Control.Feedback type="invalid">{t(formErrors?.name)}</Form.Control.Feedback> : <></>}
                            </Form.Group>
                            <Form.Group className="mb-3">
                              <Form.Label>{t('manager.stores.edit.fields.phone')}</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder={t('manager.stores.edit.fields.phone')}
                                value={phone}
                                onChange={basicOnChange(setPhone)}
                              />
                            </Form.Group>
                            <Form.Group className="mb-3">
                              <Form.Label>{t('manager.stores.edit.fields.email')}</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder={t('manager.stores.edit.fields.email')}
                                value={email}
                                onChange={basicOnChange(setEMail)}
                              />
                            </Form.Group>
                            <Form.Group className="mb-3">
                              <Form.Label>{t('manager.stores.edit.fields.website')}</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder={t('manager.stores.edit.fields.website')}
                                value={website}
                                onChange={basicOnChange(setWebsite)}
                              />
                            </Form.Group>
                          </Col>
                          <Col xs={12} sm={6}>
                            <Form.Group className="mb-3">
                              <Form.Label>{t('manager.stores.edit.fields.address')}</Form.Label>
                              <Form.Control
                                className={formErrors?.address ? 'is-invalid' : ''}
                                type="text"
                                placeholder={t('manager.stores.edit.fields.address')}
                                value={address}
                                onChange={basicOnChange(setAddress)}
                              />
                              {formErrors?.address ? <Form.Control.Feedback type="invalid">{t(formErrors?.address)}</Form.Control.Feedback> : <></>}
                            </Form.Group>
                            <Form.Group className="mb-3">
                              <Form.Label>{t('manager.stores.edit.fields.address2')}</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder={t('manager.stores.edit.fields.address2')}
                                value={address2}
                                onChange={basicOnChange(setAddress2)}
                              />
                            </Form.Group>
                            <Form.Group className="mb-3">
                              <Form.Label>{t('manager.stores.edit.fields.countryZipCity')}</Form.Label>
                              <Row>
                                <Col xs={4} lg={2}>
                                  <Form.Control
                                    className={formErrors?.country ? 'is-invalid' : ''}
                                    type="text"
                                    placeholder={t('manager.stores.edit.fields.country')}
                                    value={country}
                                    onChange={basicOnChange(setCountry)}
                                  />
                                </Col>
                                <Col xs={8} lg={4}>
                                  <Form.Control
                                    className={formErrors?.zip ? 'is-invalid' : ''}
                                    type="text"
                                    placeholder={t('manager.stores.edit.fields.zip')}
                                    value={zip}
                                    onChange={basicOnChange(setZip)}
                                  />
                                </Col>
                                <Col className="mt-1 mt-lg-0" xs={12} lg={6}>
                                  <Form.Control
                                    className={formErrors?.city ? 'is-invalid' : ''}
                                    type="text"
                                    placeholder={t('manager.stores.edit.fields.city')}
                                    value={city}
                                    onChange={basicOnChange(setCity)}
                                  />
                                </Col>
                              </Row>
                              {formErrors?.country ? <Form.Control.Feedback type="invalid" style={{display:'block'}}>{t(formErrors?.country)}</Form.Control.Feedback> : <></>}
                              {formErrors?.zip ? <Form.Control.Feedback type="invalid" style={{display:'block'}}>{t(formErrors?.zip)}</Form.Control.Feedback> : <></>}
                              {formErrors?.city ? <Form.Control.Feedback type="invalid" style={{display:'block'}}>{t(formErrors?.city)}</Form.Control.Feedback> : <></>}
                            </Form.Group>
                          </Col>
                        </Row>
                      </>
                    )
                }
              </ManagerCard>

              <ManagerCard title={t('manager.stores.edit.titleSalesmen')}>
                {
                  isLoadingCreate || isLoadingEdit
                    ? <Spinner animation="border" role="status" size="xxl" />
                    : <ManagerDynamicList
                          placeholder={t('manager.stores.edit.fields.salesmen')}
                          setData={setSalesmen}
                          selection={salesmen ?? []}
                          options={availableSalesmen ?? []}
                          columns={columnsSalesmen}
                          isAdmin={(manager?.isAdmin ?? false) || isManagerOfStore}
                          actionsInFront={true}
                          displayProperty={salesmenDisplay}
                          filterProperties={['ean', 'firstname', 'lastname']}
                    />
                }
              </ManagerCard>

              {
                !manager?.isAdmin ?? false
                ? <></>
                : (
                    <ManagerCard title={t('manager.stores.edit.titleManagers')}>
                      {
                        isLoadingCreate || isLoadingEdit
                          ? <Spinner animation="border" role="status" size="xxl" />
                          : (
                            <>
                              {formErrors?.managers ? <Form.Control.Feedback type="invalid" style={{display:'block'}}>{t(formErrors?.managers)}</Form.Control.Feedback> : <></>}
                              <ManagerDynamicList
                                placeholder={t('manager.stores.edit.fields.managers')}
                                setData={setManagers}
                                selection={managers ?? []}
                                options={availableManagers ?? []}
                                columns={columnsManager}
                                isAdmin={manager?.isAdmin ?? false}
                                actionsInFront={true}
                                displayProperty={managerDisplay}
                                filterProperties={['username']}
                              />
                            </>
                          )
                      }
                    </ManagerCard>
                  )
              }

              {
                isLoadingCreate || isLoadingEdit
                  ? <></>
                  : (
                    <div className="controls">
                      <Button variant="light" onClick={stepBack}>{t('cancel')}</Button>
                      <Button variant="primary" onClick={save} className="ms-auto">{t('save')}</Button>
                    </div>
                  )
              }
            </>
          )
      }
    </Form>
  );
}

export default ManagerStoreEdit;