import * as React from 'react';
import {updateLanguageByParam} from '@helper/languageHelper';
import {useTranslation} from 'react-i18next';
import {useParams, useNavigate} from 'react-router-dom';
import {Col, Container, Row, Spinner} from 'react-bootstrap';
import classNames from 'classnames';
import {useCallback, useEffect, useMemo} from 'react';
import {CheckCircleFill, ExclamationCircleFill, XCircleFill} from 'react-bootstrap-icons';
import {redeemCoffee} from '@hooks/api';
import {useDispatch} from 'react-redux';
import {hideBackground, showBackground} from '@redux/slices/backgroundSlice';
import {resetHeader} from '@redux/slices/layoutSlice';

const CoffeeRedeemPage = ({}) => {
  const {langCode} = useParams();
  const {i18n} = useTranslation();
  useEffect(() => {
    updateLanguageByParam({i18n, langCode});
  }, [langCode,i18n]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(hideBackground());
    dispatch(resetHeader());
  }, []);

  const {commissionId} = useParams();
  const {t} = useTranslation();

  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  }

  const {callApi: callRedeemCoffee, states: getRedeemCoffeeState} = redeemCoffee();
  const {response} = getRedeemCoffeeState;

  const handleRedeem = useCallback((abortSignal) => {
    if (commissionId) {
      const data = {
        uuid: commissionId
      };

      callRedeemCoffee(data, {signal: abortSignal})
        .then(res => {
          if(!res.error) {
            return;
          }

          if (res.error.name !== 'AbortError') {
            console.error(res.error);
          }
        });
    }
  }, [callRedeemCoffee, commissionId]);

  useEffect(() => {
    const abortController = new AbortController();
    handleRedeem(abortController.signal);
    return () => {
      abortController.abort();
    }
  }, [handleRedeem]);

  const containerClasses = useMemo(() => {
    return classNames({
      'd-flex': true,
      'flex-column': true,
      'redeemStatus': true,
      'failed': response?.payload?.error,
      'success': response?.payload?.success,
      'alreadyRedeemed': response?.payload?.alreadyRedeemed
    });
  }, [response]);

  const statusText = useMemo(() => {
    if (response?.payload?.success) {
      return t('coffee.redeem.success');
    } else if (response?.payload?.alreadyRedeemed) {
      return t('coffee.redeem.alreadyRedeemed');
    }
    return t('coffee.redeem.failed');
  }, [response, i18n.language]);

  return (
    <Container className="dark-font">
      <Row>
        <Col>
          <a onClick={goBack}>&laquo; {t('back')}</a>
        </Col>
      </Row>
      <Row>
        <Col className={containerClasses}>
        {
          response?.payload
          ? <>
              <span className="icon">
                {
                  response.payload?.success
                  ? <CheckCircleFill />
                  : (
                    response.payload?.alreadyRedeemed
                    ? <ExclamationCircleFill />
                    : <XCircleFill />
                    )
                }
              </span>
              <span className="status">
                {statusText}
              </span>
            </>
          : <Spinner animation="border" role="status" size="xxl" />
        }
        </Col>
      </Row>
    </Container>
  );
}

export default CoffeeRedeemPage;