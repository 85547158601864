import apiClient from '@hooks/apiClient';
/********************************************************************************************
 * AccountApi
 ********************************************************************************************/

/**
 * data = { user: USER, password: PWD }
 */
export const loginManager = (options) =>
  apiClient('api/AccountApi/Login', {
    method: 'POST',
    headers: {
      'content-type': 'application/json'
    },
    ...options
  });

/**
 * data = {  }
 */
export const logoutManager = (options) =>
  apiClient('api/AccountApi/Logout', {
    method: 'POST',
    ...options
  });

/**
 * data = {  }
 */
export const getManagerInformation = (options) =>
  apiClient('api/AccountApi/GetCurrentUserInformation', {
    method: 'GET',
    ...options
  });


/********************************************************************************************
 * StoreApi
 ********************************************************************************************/

/**
 * data = {  }
 */
export const fetchManagerList = (options, isAdmin=false) =>
  apiClient('api/StoreApi/GetManagers', {
    method: 'GET',
    ...options
  });

/**
 * data = { id: ID }
 */
export const deleteManager = (options, isAdmin=false) =>
  apiClient('api/StoreApi/DeleteManager', {
    method: 'POST',
    headers: {
      'content-type': 'application/json'
    },
    ...options
  });

/**
 * data = { username: STRING, password: STRING, isAdmin: BOOL, stores: [IDs] }
 */
export const createManager = (options, isAdmin=false) =>
  apiClient('api/StoreApi/CreateManager', {
    method: 'POST',
    headers: {
      'content-type': 'application/json'
    },
    ...options
  });

/**
 * data = { id: ID, username: STRING, isAdmin: BOOL, stores: [IDs] }
 */
export const editManager = (options, isAdmin=false) =>
  apiClient('api/StoreApi/EditManager', {
    method: 'POST',
    headers: {
      'content-type': 'application/json'
    },
    ...options
  });

/**
 * data = { id: ID, username: STRING, isAdmin: BOOL, stores: [IDs] }
 */
export const resetManagerPassword = (options, isAdmin=false) =>
  apiClient('api/StoreApi/ResetPassword', {
    method: 'POST',
    headers: {
      'content-type': 'application/json'
    },
    ...options
  });


/**
 * data = {  }
 */
export const fetchSalesmenList = (options, isAdmin=false) =>{
  const apiRoute = isAdmin ? 'api/StoreApi/GetAllSalesmen' : 'api/StoreApi/GetSalesmen'
  return apiClient(apiRoute, {
    method: 'GET',
    ...options
  });
}

/**
 * data = { id: ID }
 */
export const deleteSalesman = (options, isAdmin=false) =>
  apiClient('api/StoreApi/DeleteSalesman', {
    method: 'POST',
    headers: {
      'content-type': 'application/json'
    },
    ...options
  });

/**
 * data = { name: STRING, address: STRING, address2: STRING, zip: STRING, city: STRING, country: STRING,
 *          ean: STRING, phone: STRING, email: STRING, website: STRING, stores: [IDs] }
 */
export const createSalesman = (options, isAdmin=false) =>
  apiClient('api/StoreApi/CreateSalesman', {
    method: 'POST',
    headers: {
      'content-type': 'application/json'
    },
    ...options
  });

/**
 * data = { id: ID, name: STRING, address: STRING, address2: STRING, zip: STRING, city: STRING, country: STRING,
 *          ean: STRING, phone: STRING, email: STRING, website: STRING, stores: [IDs] }
 */
export const editSalesman = (options, isAdmin=false) =>
  apiClient('api/StoreApi/EditSalesman', {
    method: 'POST',
    headers: {
      'content-type': 'application/json'
    },
    ...options
  });

/**
 * data = {  }
 */
export const getAssignedStores = (options) =>
  apiClient('api/StoreApi/GetAssignedStores', {
    method: 'GET',
    ...options
  });



/**
 * data = {  }
 */
export const fetchStoresList = (options, isAdmin=false) => {
  const apiRoute = isAdmin ? 'api/StoreApi/GetStores' : 'api/StoreApi/GetAssignedStores'
  return apiClient(apiRoute, {
      method: 'GET',
      ...options
    });
}

/**
 * data = { name: STRING, address: STRING, address2: STRING, zip: STRING, city: STRING, country: STRING,
 *          phone: STRING, email: STRING, website: STRING, manager: [IDs], manager: [salesmen] }
 */
export const createStore = (options, isAdmin=false) =>
  apiClient('api/StoreApi/CreateStore', {
    method: 'POST',
    headers: {
      'content-type': 'application/json'
    },
    ...options
  });

/**
 * data = { id: ID, name: STRING, address: STRING, address2: STRING, zip: STRING, city: STRING, country: STRING,
 *          phone: STRING, email: STRING, website: STRING, manager: [IDs], manager: [salesmen] }
 */
export const editStore = (options, isAdmin=false) =>
  apiClient('api/StoreApi/EditStore', {
    method: 'POST',
    headers: {
      'content-type': 'application/json'
    },
    ...options
  });


