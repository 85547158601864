
import CommissionHomePage from "@pages/CommissionHomePage";
import NotFoundPage from "@pages/NotFoundPage";
import DashboardPage from '@pages/DashboardPage';
import CommissionDetailPage from '@pages/CommissionDetailPage';
import CoffeeHomePage from '@pages/CoffeeHomePage';
import CoffeeRedeemPage from '@pages/CoffeeRedeemPage';
import HomePage from '@pages/HomePage';
import ManagerWelcomePage from '@pages/manager/ManagerWelcomePage';
import ManagerManagersPage from '@pages/manager/ManagerManagersPage';
import ManagerStoresPage from '@pages/manager/ManagerStoresPage';
import ManagerSalesmenPage from '@pages/manager/ManagerSalesmenPage';
import ManagerManagerEdit from '@components/manager/ManagerManagerEdit';
import ManagerSalesmanEdit from '@components/manager/ManagerSalesmanEdit';
import ManagerStoreEdit from '@components/manager/ManagerStoreEdit';
import ManagerManagerEditPage from '@pages/manager/ManagerManagerEditPage';
import ManagerSalesmanEditPage from '@pages/manager/ManagerSalesmanEditPage';
import ManagerStoreEditPage from '@pages/manager/ManagerStoreEditPage';

const AppRoutes = [
  {
    index: true,
    element: <HomePage />
  },
  {
    path: '/scm',
    element: <CommissionHomePage />
  },
  {
    path: '*',
    element: <NotFoundPage />
  },
  {
    path: '/scm/:langCode/dashboard/store/:storeId/:viewMode?',
    element: <DashboardPage />
  },
  {
    path: '/scm/:langCode/commission/details/:commissionId',
    element: <CommissionDetailPage />
  },
  {
    path: '/scm/coffee',
    element: <CoffeeHomePage />
  },
  {
    path: '/scm/coffee/redeem/:commissionId',
    element: <CoffeeRedeemPage />
  },


  /****************************************************************************
   * ADMINISTRATION
   ****************************************************************************/
  {
    path: '/manager',
    element: <ManagerWelcomePage />
  },
  {
    path: '/manager/managers',
    element: <ManagerManagersPage />
  },
  {
    path: '/manager/managers/create',
    element: <ManagerManagerEditPage />
  },
  {
    path: '/manager/managers/edit',
    element: <ManagerManagerEditPage />
  },
  {
    path: '/manager/stores',
    element: <ManagerStoresPage />
  },
  {
    path: '/manager/stores/create',
    element: <ManagerStoreEditPage />
  },
  {
    path: '/manager/stores/edit',
    element: <ManagerStoreEditPage />
  },
  {
    path: '/manager/salesmen',
    element: <ManagerSalesmenPage />
  },
  {
    path: '/manager/salesmen/create',
    element: <ManagerSalesmanEditPage />
  },
  {
    path: '/manager/salesmen/edit',
    element: <ManagerSalesmanEditPage />
  },
];

export default AppRoutes;
