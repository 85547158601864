import appConfig from '@src/config/config';

export const persistInLocalStorage = (stateName, state, prefix= appConfig.manager.storagePrefix) => {
  if (state !== null) {
    const dataType = typeof state;
    const data = dataType === 'object' || dataType === 'array' ? JSON.stringify(state) : state;
    localStorage.setItem(prefix+stateName, data);
  } else {
    removeFromLocalStorage(stateName);
  }
}

export const restoreFromLocalStorage = (stateName, prefix= appConfig.manager.storagePrefix) => {
  const data = localStorage.getItem(prefix+stateName);

  try {
    return JSON.parse(data);
  } catch (e) {
    return data;
  }
}


export const removeFromLocalStorage = (stateName, prefix= appConfig.manager.storagePrefix) => {
  localStorage.removeItem(prefix+stateName);
}
