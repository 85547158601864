import * as React from 'react';
import {Button, Form, Spinner} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {useState} from 'react';
import {getManagerInformation, loginManager} from '@hooks/managerApi';
import {snackbarSuccess, snackbarError} from '@helper/snackbarHelper';


const ManagerLogin = ({setManager, setManagerToken}) => {
  const {t} = useTranslation();

  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');

  const {callApi: callManagerLogin, states: getLoginState} = loginManager();
  const {responseLogin, isLoadingLogin} = getLoginState;


  const updateUser = (event) => {
    setUser(event.target.value.toString());
  }
  const updatePassword = (event) => {
    setPassword(event.target.value.toString());
  }

  const login = (event) => {
    event.preventDefault();
    if (user === '') {
      snackbarError(t('manager.snackbar.missingUser'));
      return;
    }

    if (password === '') {
      snackbarError(t('manager.snackbar.missingPassword'));
      return;
    }

    const data = {
      user: user,
      password: password,
    };

    callManagerLogin(data)
      .then(res => {
        if(!res.error) {
          const token = res.payload?.token ?? null;
          setManagerToken(token);
          if (token) {
            snackbarSuccess(t('manager.snackbar.loginSuccess'));
            return;
          }
        }
        snackbarError(t('manager.snackbar.loginFailed'));
        if (res.error) console.error(res.error);
      });
  }


  return (
    <div className="scm-login">
      {
        isLoadingLogin
        ? <Spinner animation="border" role="status" size="xxl" />
        : (
          <>
            <h4 className="mb-3">{t('manager.login.title')}</h4>
            <Form onSubmit={login} method="POST">
              <Form.FloatingLabel className="mb-2" controlId="loginForm.User" label={t('manager.login.username')}>
                <Form.Control type="text" onChange={updateUser} value={user} placeholder={t('manager.login.username')}/>
              </Form.FloatingLabel>
              <Form.FloatingLabel controlId="loginForm.Password" label={t('manager.login.password')}>
                <Form.Control type="password" onChange={updatePassword} value={password} placeholder="*****"/>
              </Form.FloatingLabel>
              <Button type="submit">{t('manager.login.login')}</Button>
            </Form>
          </>
        )
      }
    </div>
  );
}

export default ManagerLogin;