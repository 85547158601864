
import defaultBackgroundVideoMP4 from '@assets/videos/background.mp4';
import defaultBackgroundVideoWEBM from '@assets/videos/background.webm';

const appConfig = {
  defaultVideo: {
    webm: defaultBackgroundVideoWEBM,
    mp4: defaultBackgroundVideoMP4,
  },
  bwtHomePage: {
    url: 'https://www.bwt.com'
  },
  urls:{
    'commissionDetailPage': '/scm/:langCode/commission/details/:commissionId',
    'coffeeRedeemPage': '/scm/coffee/redeem/:commissionId',
  },
  commissionList: {
    api :{
      pullInterval: 30 * 1000,
    },
    flip: {
      interval: 7000,
      duration: 1500,
      pageSize: 3,
    }
  },
  commissionReview: {
    rateUrl: 'https://g.page/r/CTi1CBSfF3AIEB0/review?utm_source=bestwatershop'
  },
  commissionDetails: {
    api: {
      pullInterval: 30 * 1000,
    },
  },
  commissionStateList: {
    visibleStates: [
      'inProgress', 'paid', 'readyForPickup', 'handover', 'cancelled'
    ],
    badgeSize: 40,
  },
  manager: {
    storagePrefix: 'scm-',
    eanPrefix: 299,
    routes: {
      overview: {
        requireAdmin: false,
        path: '/manager',
      },
      overviewManagers: {
        requireAdmin: true,
        path: '/manager/managers',
        children: [
          {
            requireAdmin: true,
            path: '/create'
          },
          {
            requireAdmin: true,
            path: '/edit'
          }
        ]
      },
      overviewStores: {
        requireAdmin: false,
        path: '/manager/stores',
        children: [
          {
            requireAdmin: true,
            path: '/create'
          },
          {
            requireAdmin: false,
            path: '/edit'
          }
        ]
      },
      overviewSalesmen: {
        requireAdmin: false,
        path: '/manager/salesmen',
        children: [
          {
            requireAdmin: false,
            path: '/create'
          },
          {
            requireAdmin: false,
            path: '/edit'
          }
        ]
      },
    }
  },
  snackbar: {
    defaultAutoHideDuration: 3000,
  },
}

export default appConfig;