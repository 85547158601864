import * as React from 'react';
import Header from '@components/Header'
import Background from '@components/Background';

const Layout = ({children}) => {
  return (
    <>
      <Header />
      <Background />
      {children}
    </>
  );
}

export default Layout;