import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {useContext} from 'react';
import {ManagerContext} from '@components/manager/ManagerContainer';
import ManagerCard from '@components/manager/ManagerCard';
import {Spinner} from 'react-bootstrap';
import ManagerTable from '@components/manager/ManagerTable';
import {fetchStoresList} from '@hooks/managerApi';

const ManagerStoresList = () => {
  const {manager, managerToken} = useContext(ManagerContext);

  const {t} = useTranslation();

  const columns = [
    {
      label: t('manager.stores.columns.name'),
      property: 'name',
    },
    {
      label: t('manager.stores.columns.address'),
      property: 'address',
    },
    {
      label: t('manager.stores.columns.country'),
      property: 'country',
    },
    {
      label: t('manager.stores.columns.zip'),
      property: 'zip',
    },
    {
      label: t('manager.stores.columns.city'),
      property: 'city',
    },
    {
      label: t('manager.stores.columns.numberSalesmen'),
      property: (data) => {
        return data?.salesmen?.length ?? 0;
      },
      adminOnly: true,
    },
  ];

  const deleteAction = {
    delete: null
  }

  return (
    <ManagerCard title={t('manager.stores.title')}>
      {
        (!manager)
          ? <Spinner animation="border" role="status" size="xxl" />
          : <ManagerTable fetchApi={fetchStoresList} apiResponseProperty="stores" authToken={managerToken} columns={columns} rowActions={deleteAction} isAdmin={manager.isAdmin ?? false} />
      }
    </ManagerCard>
  );
}

export default ManagerStoresList;