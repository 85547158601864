import { configureStore } from '@reduxjs/toolkit'
import backgroundReducer from "@redux/slices/backgroundSlice";
import layoutReducer from "@redux/slices/layoutSlice"
import commissionsSlice from '@redux/slices/commissionsSlice';
import configSlice from '@redux/slices/configSlice';

export const store = configureStore({
  reducer: {
    background: backgroundReducer,
    layout: layoutReducer,
    commissions: commissionsSlice,
    config: configSlice,
  },
})