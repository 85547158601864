import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {useContext, useEffect, useState} from 'react';
import {ManagerContext} from '@components/manager/ManagerContainer';
import ManagerCard from '@components/manager/ManagerCard';
import ManagerTable from '@components/manager/ManagerTable';
import {deleteSalesman, fetchSalesmenList, getAssignedStores} from '@hooks/managerApi';
import {Badge, Spinner} from 'react-bootstrap';
import ManagerBarcodeModal from '@components/manager/ManagerBarcodeModal';
import {snackbarError, snackbarSuccess} from '@helper/snackbarHelper';
import {PlusCircle} from 'react-bootstrap-icons';

const ManagerSalesmenList = () => {
  const {manager, managerToken} = useContext(ManagerContext);
  const [tableDataLoaded, setTableDataLoaded] = useState(false);

  const [filter, setFilter] = useState(null);
  const {callApi: callGetAssignedStores, states: getStateAssignedStores} = getAssignedStores();
  const {response: responseGetAssignedStores, isLoading: isLoadingAssignedStores} = getStateAssignedStores;

  useEffect(() => {
    callGetAssignedStores(null, managerToken)
      .then(res => {
        if(res.error) {
          console.error(res.error);
          return;
        }
        let storeIds = res.payload.stores.map((store) => store.id);
        setFilter({storeIds: storeIds.join(',')});
      });
  }, [managerToken]);


  const handleDataLoaded= (value) => {
    setTableDataLoaded(value);
  };

  const {callApi: callDeleteSalesman, states: getState} = deleteSalesman();
  const {response, isLoading: isDeleting} = getState;


  const {t} = useTranslation();

  const columns = [
    {
      label: t('manager.salesmen.columns.lastname'),
      property: 'lastname',
    },
    {
      label: t('manager.salesmen.columns.firstname'),
      property: 'firstname',
    },
    {
      label: t('manager.salesmen.columns.ean'),
      property: (data) => {
        return <ManagerBarcodeModal value={data.ean} barcodeFormat="EAN13" barcodeHeight={40} fileName={`${data.firstname}_${data.lastname}_${data.ean}`} />;
      },
    },
  ];

  const deleteAction = {
    delete: {
      action: (id) => {
        callDeleteSalesman({Id: id}, managerToken)
          .then(res => {
            if(!res.error) {
              snackbarSuccess(t('manager.snackbar.deleteSalesmanSuccess'));
              handleDataLoaded(false);
              return;
            }
            snackbarError(t('manager.snackbar.deleteSalesmanFailed'));
            if (res.error) console.error(res.error);
          });
      },
      confirmModal: {
        title: t('manager.salesmen.confirmModal.delete.title'),
        content: t('manager.salesmen.confirmModal.delete.content'),
        btnText: t('manager.salesmen.confirmModal.delete.btnText'),
        btnVariant: "danger",
      }
    }
  }

  const addAction = {
    create: {
      adminOnly: false,
    },
  }

  return (
    <ManagerCard title={t('manager.salesmen.title')}>
      {
        (!manager || isDeleting || isLoadingAssignedStores)
          ? <Spinner animation="border" role="status" size="xxl" />
          : <ManagerTable
                fetchApi={fetchSalesmenList}
                additionalApiParameters={filter}
                apiResponseProperty="salesmen"
                authToken={managerToken}
                columns={columns}
                rowActions={deleteAction}
                headerActions={addAction}
                isAdmin={manager.isAdmin ?? false}
                loaded={tableDataLoaded}
                handleLoaded={handleDataLoaded}/>
      }
    </ManagerCard>
  );
}

export default ManagerSalesmenList;